import React from 'react';
import { Box, Typography, Avatar, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles'; // Import styled from MUI
import { ICourse } from '../../types/interfaces';
import { CATEGORIES } from '../../store/data';
import CategoryCard from '../Shared/CategoryCard';
import { useNavigate } from 'react-router-dom';

const OutlineContent = styled(Box)(({ theme }) => ({
	paddingBottom: theme.spacing(2),
	paddingTop: theme.spacing(4),
}));

const EducatorBioBlock = styled(Box)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.grey[400]}`,
	borderBottom: `1px solid ${theme.palette.grey[400]}`,
	padding: theme.spacing(2),
}));

const EducatorBioInnerContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
});

const SummaryBlock = styled(Box)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.grey[400]}`,
	borderBottom: `1px solid ${theme.palette.grey[400]}`,
	paddingBottom: theme.spacing(2),
	paddingTop: theme.spacing(2),
}));

const CategoriesBlock = styled(Box)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.grey[400]}`,
	borderBottom: `1px solid ${theme.palette.grey[400]}`,
	paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
}));

const CategoriesRow = styled(Box)({
	display: 'flex',
	paddingVertical: '8px', // You may adjust this as needed
	overflowX: 'auto', // Enables horizontal scrolling
	whiteSpace: 'nowrap',
	paddingBottom: 12,
	paddingTop: 2,
});

const Tag = styled(Chip)(({ theme }) => ({
	margin: theme.spacing(0.5),
}));

interface IProps {
	course: ICourse;
	totalDurationMillis: number;
}

export default function CoursePreviewTab({ course }: IProps) {
    	const navigate = useNavigate();

	const hasTags = course?.tags.length > 0;
	const educatorName = `${course?.['tutor_name']}`;
	const summaryList = course?.summary_list ?? [];
	const tutorProfileImage = course?.['tutor_profile_image'];

	const viewEducatorProfile = () => {
		navigate(`/app/educators/${course.tutor_id}`);
	};

	return (
		<>
			<OutlineContent>
				<Typography variant='h6'>OUTLINE</Typography>
				<Typography>{course?.description}</Typography>
			</OutlineContent>

			<EducatorBioBlock>
				<Typography variant='h6'>EDUCATOR'S BIO</Typography>
				<EducatorBioInnerContainer>
					<Avatar
						src={tutorProfileImage || '/images/defaultEducatorProfileImage.png'}
						alt={educatorName}
						sx={{ width: 80, height: 80 }}
					/>
					<Box marginLeft={2} flexGrow={1}>
						<Button onClick={viewEducatorProfile} sx={{paddingLeft: 0}}>
							<Typography variant='body1' noWrap sx={{alignSelf:"start", fontWeight: 500}}>
								{educatorName}
							</Typography>
						</Button>
						<Typography variant='body2'>{course?.['tutor_bio']}</Typography>
					</Box>
				</EducatorBioInnerContainer>
			</EducatorBioBlock>

			<SummaryBlock>
				<Typography variant='h6'>WHAT YOU WOULD LEARN</Typography>
				{summaryList.map((item, i) => (
					<Typography key={i} variant='body2' gutterBottom>
						{`${i + 1}. ${item}`}
					</Typography>
				))}
			</SummaryBlock>

			<CategoriesBlock>
				<Typography variant='h6'>COURSE CATEGORIES</Typography>
				<CategoriesRow>
					{course?.categories.map((category, i) => (
						<CategoryCard
							key={`category-${i}`}
							category={category}
							imageSource={CATEGORIES[category]?.image}
						/>
					))}
				</CategoriesRow>
			</CategoriesBlock>

			{hasTags && (
				<CategoriesBlock>
					<Typography variant='h6'>TAGS</Typography>
					<CategoriesRow>
						{course?.tags.map((tag, i) => (
							<Tag key={`tag-${i}`} label={tag} />
						))}
					</CategoriesRow>
				</CategoriesBlock>
			)}
		</>
	);
}
