import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function CheckoutSuccess() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '80vh',
				padding: 3,
				textAlign: 'center',
			}}>
			<CheckCircleOutlineIcon
				sx={{ fontSize: 64, color: 'success.main', mb: 2 }}
			/>

			<Typography variant='h4' component='h1' gutterBottom>
				Payment Successful!
			</Typography>

			<Typography
				variant='body1'
				color='text.secondary'
				sx={{ mb: 4, maxWidth: 600 }}>
				Thank you for your subscription. You now have access to all the
				educator's content.
			</Typography>

			<Box sx={{ display: 'flex', gap: 2 }}>
				<Button
					variant='contained'
					onClick={() => navigate('/app')}
					sx={{ minWidth: 120 }}>
					Go to Dashboard
				</Button>

				<Button
					variant='outlined'
					onClick={() => navigate('/app/search')}
					sx={{ minWidth: 120 }}>
					Browse Content
				</Button>
			</Box>
		</Box>
	);
}
