import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface IProps {
	imageSource: string; // Assuming the imageSource is a URL or local path
	category: string;
}

const StyledContainer = styled(Box)(({ theme }) => ({
	marginRight: theme.spacing(2), // Equivalent to marginRight: 15
	width: 104, // Fixed width for the card
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
}));

const StyledImage = styled('img')({
	width: 104,
	height: 104,
	borderRadius: 4,
});

export default function CategoryCard({ imageSource, category }: IProps) {
	return (
		<StyledContainer>
			<StyledImage src={imageSource} alt={category} />
			<Typography
				variant='body2'
				sx={{
					maxWidth: 104,
					marginTop: 1,
					fontSize: 14,
					fontWeight: 600, // SemiBold equivalent
					color: 'text.primary', // This assumes you have a theme color defined
					overflowWrap: 'break-word', // Allows breaking long words
					wordWrap: 'break-word', // Legacy support
					hyphens: 'auto', // Adds hyphenation
					overflow: 'hidden', // Prevents overflow
					textOverflow: 'ellipsis', // Optional: adds ellipsis for overflowing text
					whiteSpace: 'normal', // Allows the text to wrap
				}}>
				{category}
			</Typography>
		</StyledContainer>
	);
}
