import React from 'react';
import { Box } from '@mui/material';
import { IResource } from '../../types/interfaces';
import ResourceListItem from './ResourceListItem';

interface IProps {
	resources: IResource[] | null;
}

export default function Resources({ resources }: IProps) {
	return (
		<Box
			sx={{
				width: '95%',
				alignItems: 'center',
				margin: 'auto',
				display: 'flex',
				flexDirection: 'column',
				paddingY: 2,
			}}>
			{resources?.map((resource, i) => (
				<ResourceListItem
					index={i}
					title={resource.title}
					description={resource.description}
					key={`${resource.title}-${i}`} // Use a more unique key
				/>
			))}
		</Box>
	);
}
