import React, { useEffect } from 'react';
import { run } from 'tripetto-runner-autoscroll';
import Services from 'tripetto-services';
import './EducatorsForm.css';

const key = process.env.REACT_APP_TRIPETTO_TOKEN;
function EducatorsForm() {
	//Nothing here is conventional
	const tripetto = Services.init({
		token: key as string,
	});

	useEffect(() => {
		const navigationElement = document?.querySelector(
			'.navigation'
		) as HTMLElement;

		navigationElement.classList.add('navigation-with-background');
		const tripElement = document.getElementById('tripetto');
		tripElement?.addEventListener('scroll', () => {
			navigationElement.classList.add('navigation-with-background');
		});

		run({
			element: document.getElementById('tripetto'),
			definition: tripetto.definition,
			styles: tripetto.styles,
			l10n: tripetto.l10n,
			locale: tripetto.locale,
			translations: tripetto.translations,
			onSubmit: tripetto.onSubmit,
			persistent: true,
		});
	});
	return (
		<main className='main container'>
			<div id='tripetto'></div>
		</main>
	);
}

export default EducatorsForm;
