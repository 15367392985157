import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const ScalableSection = ({ children, scaleStart = 0.2, scaleEnd = 1 }: any) => {
	const { scrollYProgress } = useScroll();
	// Use useTransform to scale based on scrollYProgress
	const scale = useTransform(scrollYProgress, [0, 1], [scaleStart, scaleEnd]);

	return <motion.div style={{ scale }}>{children}</motion.div>;
};

export default ScalableSection;
