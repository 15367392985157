import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import Header from './Components/Shared/Header/Header';
import LandingView from './Components/LandingView/LandingView';
import ForEducators from './Components/ForEducators/ForEducators';
import Footer from './Components/Shared/Footer/Footer';
import FAQsPage from './Components/FAQsPage/FAQsPage';
import TermsOfServices from './Components/LegalPages/TermsOfServices';
import PrivacyPolicy from './Components/LegalPages/PrivacyPolicy';
import EducatorsForm from './Components/EducatorsForm/EducatorsForm';
import GetWaitList from './Components/GetWaitList/GetWaitList';
import Login from './Components/Login/Login';
import SearchPage from './Components/Search/SearchPage';
import CourseDetail from './Components/CourseDetail/CourseDetail';
import Profile from './Components/Profile/Profile';
import Container from '@mui/material/Container';
import SubscribeToEducator from './Components/SubscribeToEducator/SubscribeToEducator';
import CheckoutSuccess from './Components/StripeCheckout/CheckoutSuccess';
import CheckoutFailed from './Components/StripeCheckout/CheckoutCanceled';
import CheckoutCanceled from './Components/StripeCheckout/CheckoutCanceled';
import { getTokens, getTokensExpirationStatus } from './store/asyncStorage';
import { ProfileService } from './api/profileService';
import { ToasterDuration } from './types/constants';
import { ToasterType } from './types/interfaces';
import { ToasterService } from './MainUtils/ToasterService';
import Settings from './Components/Settings/Settings';

const AppParent = () => {
	return (
		<Container
			maxWidth='xs'
			className='home-container'
			sx={{
				minHeight: '100vh',
				// overflowY: 'scroll',
				// overflow: 'hidden',
				paddingBottom: 30,
			}}>
			<div
				style={{
					backgroundColor: '#F4F5F9',
				}}>
				{/* Outlet will render child components */}
				<Outlet />
			</div>
		</Container>
	);
};

function App() {
	const navigate = useNavigate();

	useEffect(() => {
		const checkAuthAndLoadUser = async () => {
			// Only check auth for /app/* routes
			if (!window.location.pathname.startsWith('/app/')) {
				return;
			}

			const tokens = await getTokens();
			const tokenExpStatus = await getTokensExpirationStatus();

			if (
				tokens?.id &&
				tokenExpStatus?.isUserLoggedIn &&
				!tokenExpStatus.refreshExpired
			) {
				// Token is valid, load user data
				await ProfileService.loadAndStoreProfileData(tokens.id);
			} else {
				if (!window.location.pathname.includes('/app/login')) {
					ToasterService.dispatchAddToaster({
						message: 'Your session has expired. Please log in again.',
						type: ToasterType.INFO,
						duration: ToasterDuration.SHORT,
					});
					ProfileService.clearUserDataFromStore();
					// Token expired or missing, redirect to login
					navigate('/app/login');
				}
			}
		};

		checkAuthAndLoadUser();
	}, [navigate]);

	return (
		<div>
			<Header />
			<Routes>
				<Route path='/' element={<LandingView />} />
				<Route path='/for-educators' element={<ForEducators />} />

				<Route path='/faqs' element={<FAQsPage />} />
				<Route path='/terms' element={<TermsOfServices />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/wait-list' element={<GetWaitList />} />
				<Route path='/app' element={<AppParent />}>
					{/* Redirect /app to /app/login */}
					<Route index element={<Navigate to='/app/login' />} />
					<Route path='login' element={<Login />} />
					<Route path='search' element={<SearchPage />} />
					<Route path='educators-get-started' element={<EducatorsForm />} />
					{/* Define routes for course details */}
					<Route path='courses/:id' element={<CourseDetail />} />

					{/* Define routes for educator profile */}
					<Route path='educators/:id' element={<Profile />} />
					<Route
						path='subscribe-to-educator'
						element={<SubscribeToEducator />}
					/>
					<Route path='subscription-success' element={<CheckoutSuccess />} />
					<Route path='subscription-canceled' element={<CheckoutCanceled />} />
					<Route path='settings' element={<Settings />} />
				</Route>

				{/* Redirect all unknown paths to '/' */}
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
