export const defaultTopAvailableCategories = [
	'Arts and Humanities',
	'Business and Finance',
	'Sports',
	'Music',
	'Health and Wellness',
	'Creative Arts',
];

export const CATEGORIES = {
	Languages: {
		tags: [
			'English',
			'Spanish',
			'Mandarin',
			'French',
			'German',
			'Japanese',
			'Italian',
			'Arabic',
			'Portuguese',
			'Russian',
			'Sign Language',
		],
		image: '/images/Categories/Languages.jpg',
	},
	'Business and Finance': {
		tags: [
			'Entrepreneurship',
			'Marketing',
			'Accounting',
			'Personal Finance',
			'Investing',
			'Real Estate',
			'Economics',
			'Business Strategy',
			'Leadership',
			'Project Management',
		],
		image: '/images/Categories/Business-and-Finance.jpeg',
	},
	'Technology and Programming': {
		tags: [
			'Web Development',
			'Mobile App Development',
			'Data Science',
			'Artificial Intelligence',
			'Cybersecurity',
			'Blockchain',
			'Cloud Computing',
			'Game Development',
			'IT and Networking',
		],
		image: '/images/Categories/Technology.jpg',
	},
	'Science and Mathematics': {
		tags: [
			'Physics',
			'Chemistry',
			'Biology',
			'Astronomy',
			'Environmental Science',
			'Geology',
			'Mathematics',
			'Algebra',
			'Calculus',
			'Statistics',
			'Engineering',
		],
		image: '/images/Categories/Science-and-Mathematics.jpeg',
	},
	'Arts and Humanities': {
		tags: [
			'History',
			'Literature',
			'Philosophy',
			'Music Theory',
			'Art History',
			'Theater',
			'Creative Writing',
			'Film Studies',
			'Linguistics',
		],
		image: '/images/Categories/Arts-and-Humanities.jpeg',
	},
	'Health and Wellness': {
		tags: [
			'Nutrition',
			'Exercise Science',
			'Mental Health',
			'Yoga and Meditation',
			'Public Health',
			'Personal Development',
			'Sleep Science',
			'Mindfulness',
			'Stress Management',
		],
		image: '/images/Categories/Health-and-Wellness.jpeg',
	},
	'Social Sciences': {
		tags: [
			'Psychology',
			'Sociology',
			'Political Science',
			'Anthropology',
			'Economics',
			'Education',
			'Gender Studies',
			'Cultural Studies',
			'International Relations',
		],
		image: '/images/Categories/Social-Sciences.jpg',
	},
	'Professional Skills': {
		tags: [
			'Communication Skills',
			'Negotiation Skills',
			'Time Management',
			'Career Development',
			'Public Speaking',
			'Networking',
			'Writing and Editing',
			'Research Skills',
			'Conflict Resolution',
		],
		image: '/images/Categories/Professional-Skills.jpeg',
	},
	'Creative Arts': {
		tags: [
			'Drawing',
			'Painting',
			'Photography',
			'Graphic Design',
			'Fashion Design',
			'Interior Design',
			'Culinary Arts',
			'Crafting',
			'Digital Art',
			'Music Production',
		],
		image: '/images/Categories/Creative-Arts.jpeg',
	},
	'Hobbies and Lifestyle': {
		tags: [
			'Travel',
			'Gardening',
			'Cooking',
			'DIY Projects',
			'Home Improvement',
			'Outdoor Activities',
			'Pet Care',
			'Collecting',
			'Video Games',
		],
		image: '/images/Categories/Hobbies-and-Lifestyle.jpeg',
	},
	Sports: {
		tags: [
			'Basketball',
			'Tennis',
			'Cricket',
			'Golf',
			'Baseball',
			'American Football',
			'Rugby',
			'Martial Arts',
			'Swimming',
			'Athletics',
			'Cycling',
			'Motor Sports',
			'Skiing',
			'Snowboarding',
			'Fitness Training',
		],
		image: '/images/Categories/Sports.jpg',
	},
	'Personal Growth': {
		tags: [
			'Self-Improvement',
			'Motivation',
			'Goal Setting',
			'Journaling',
			'Confidence Building',
			'Relationships',
			'Parenting',
			'Financial Independence',
			'Minimalism',
		],
		image: '/images/Categories/Personal-Growth.jpeg',
	},
	'Current Events and News': {
		tags: [
			'World News',
			'Local News',
			'Technology News',
			'Business News',
			'Science News',
			'Health News',
			'Entertainment News',
			'Sports News',
			'Political News',
		],
		image: '/images/Categories/Current-Events-and-News.jpeg',
	},
	'Religious and Spiritual Studies': {
		tags: [
			'Christianity',
			'Islam',
			'Buddhism',
			'Hinduism',
			'Judaism',
			'New Age Spirituality',
			'Comparative Religion',
			'Theology',
			'Ethics',
		],
		image: '/images/Categories/Religion-and-Spirituality.jpeg',
	},
	Music: {
		tags: [
			'Music Theory',
			'Music History',
			'Instrumental Lessons',
			'Guitar',
			'Piano',
			'Violin',
			'Drums',
			'Vocal Training',
			'Music Production',
			'Songwriting',
			'Music Appreciation',
			'Classical',
			'Jazz',
			'Rock',
			'Pop',
			'Hip-Hop',
			'Electronic',
			'Music Business',
			'Music Technology',
			'Music Therapy',
			'Ethnomusicology',
			'Conducting',
			'Music Composition',
			'Performance Techniques',
		],
		image: '/images/Categories/Music.jpg',
	},
};
