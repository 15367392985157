import { Button, Alert } from '@mui/material';
import React, { useState } from 'react';
import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import { IPaymentMethod } from '../../types/interfaces';

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
	throw new Error('Stripe public key is not defined');
}


export default function PaymentMethod({
	onContinue,
    setPaymentMethodProcessed,
}: {
	onContinue: (paymentMethod: IPaymentMethod) => void;
    setPaymentMethodProcessed: (paymentMethodProcessed: boolean) => void;
}) {
	const stripe = useStripe();
	const elements = useElements();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsProcessing(true);

		const { error: submitError } = await elements.submit();
		if (submitError) {
			setErrorMessage(submitError.message ?? 'An error occurred');
			setIsProcessing(false);
			return;
		}

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			elements,
		});

		if (error) {
			setErrorMessage(error.message ?? 'An error occurred');
			setIsProcessing(false);
			return;
		}
		onContinue({
			id: paymentMethod?.id ?? '',
			card: paymentMethod?.card ?? {
				brand: '',
				last4: '',
				exp_month: 0,
				exp_year: 0,
			},
            type: paymentMethod?.type ?? '',
		});

		setIsProcessing(false);
        setPaymentMethodProcessed(true);
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />

			{errorMessage && (
				<Alert severity='error' sx={{ mt: 2 }}>
					{errorMessage}
				</Alert>
			)}

			<Button
				type='submit'
				variant='outlined'
				fullWidth
				disabled={!stripe || isProcessing}
				sx={{ mt: 3, }}>
				{isProcessing ? 'Processing...' : 'Continue'}
			</Button>
		</form>
	);
}
