// src/services/ToasterService.ts
import { IToasterOptions } from '../types/interfaces';

let openSnackbar: (toaster: IToasterOptions | null) => void;

export const ToasterService = {
	setOpenSnackbar: (setter: (toaster: IToasterOptions | null) => void) => {
		openSnackbar = setter;
	},

	dispatchAddToaster: (toaster: IToasterOptions) => {
		openSnackbar(toaster);
	},

	dispatchRemoveToaster: () => {
		openSnackbar(null);
	},
};
