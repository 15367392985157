export const SET_USER_ID = 'SET_USER_ID';
export const SIGN_OUT = 'SIGN_OUT';
export const RESET_STORE = 'RESET_STORE';

export const RESET_COURSE_CATEGORY = 'RESET_COURSE_CATEGORY';
export const SET_COURSE_CATEGORY = 'SET_COURSE_CATEGORY';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_EDUCATOR_PROFILE_DATA = 'SET_EDUCATOR_PROFILE_DATA';
export const UPDATE_COURSE_CATEGORY_BY_NAME = 'UPDATE_COURSE_CATEGORY_BY_NAME'
export const SET_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';

export const SET_EDUCATOR_MEMBERSHIP_DATA = 'SET_EDUCATOR_MEMBERSHIP_DATA';
