import React from 'react';
import { Box, Typography } from '@mui/material';

interface IProps {
	index: number;
	title: string;
    description: string
}

export default function ResourceListItem({index, title, description }: IProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: 2,
				marginTop: 2,
				borderWidth: 1,
				borderColor: 'grey.400',
				borderRadius: 2,
				backgroundColor: 'white',
				width: '100%',
			}}>
			<Typography
				variant='body1'
				noWrap
				sx={{
					color: 'text.primary',
					fontWeight: 600,
					marginBottom: 1,
				}}>
				{`${index + 1}. ${title}`}
			</Typography>

			<Typography
				variant='body2'
				sx={{ color: 'text.secondary', marginBottom: 2 }}>
				{description}
			</Typography>
		</Box>
	);
}
