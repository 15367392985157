import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import * as Helpers from './reducers';
import { IStore } from '../types/interfaces';
import { effectsMiddleware } from './effects';
import * as ACTION_TYPES from './action.types';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

// Initial state with _persist property
const initialState: IStore & {
	_persist: { version: number; rehydrated: boolean };
} = {
	userData: null,
	educatorProfileData: {
		courses: [],
		coursesCount: 0,
		drafts: [],
		draftsCount: 0,
	},
	courseCategories: [],
	searchHistory: [],
	_persist: {
		version: 1,
		rehydrated: false,
	},
};

// Reducer...
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_USER_ID:
			return Helpers.applySetUserId(state, action);
		case ACTION_TYPES.SET_COURSE_CATEGORY:
			return Helpers.applySetCourseCategory(state, action);
		case ACTION_TYPES.SET_USER_DATA:
			return Helpers.applySetUserData(state, action);
		case ACTION_TYPES.RESET_COURSE_CATEGORY:
			return Helpers.applyResetCourseCategory(state);
		case ACTION_TYPES.UPDATE_COURSE_CATEGORY_BY_NAME:
			return Helpers.applyUpdateCourseCategoryByName(state, action);
		case ACTION_TYPES.SET_EDUCATOR_PROFILE_DATA:
			return Helpers.applySetEducatorProfileData(state, action);
		case ACTION_TYPES.SET_SEARCH_HISTORY:
			return Helpers.applySetSearchHistory(state, action);
		case ACTION_TYPES.SET_EDUCATOR_MEMBERSHIP_DATA:
			return Helpers.applySetEducatorMembershipData(state, action);
		case ACTION_TYPES.RESET_STORE:
			return Helpers.applyStoreRest(initialState);
		default:
			return {
				...state,
			};
	}
};

// Configure persist settings
const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		'educatorProfileData',
		'courseObjList',
		'userData',
	],
};

// Create persisted reducer
const reduxPersistReducer = persistReducer(persistConfig, reducer);

// Middleware setup
const middlewares = [effectsMiddleware];

// Store setup
const store = configureStore({
	reducer: reduxPersistReducer,
	preloadedState: initialState,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

// Create persisted store
const reduxPersistStore = persistStore(store);

export { reduxPersistStore, store };
