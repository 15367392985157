import React from 'react';
const Logo = '/images/Logo.png';
export const socialMediaIcons = {
	instagram: '/images/vectors/landingPage/instagramIcon.png', // Direct path
	facebook: '/images/vectors/landingPage/facebookIcon.png', // Direct path
	twitter: '/images/vectors/landingPage/twitterIcon.png', // Direct path
	linkedin: '/images/vectors/landingPage/linkedInIcon.png', // Direct path
	tiktok: '/images/vectors/landingPage/tiktokIcon.png', // Direct path
};

import './Footer.css';
function Footer() {
	return (
		<footer className='footer'>
			<img src={Logo} alt='logo' className='logo-icon' />
			<div className='social-links'>
				<a href='/facebook' target='_blank' rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.facebook}
						alt='logo'
						className='social-icon'
					/>
				</a>
				<a
					href='https://www.instagram.com/memoryzehq/'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.instagram}
						alt='logo'
						className='social-icon'
					/>
				</a>
				<a
					href='https://www.linkedin.com/company/memoryze-co/about/'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.linkedin}
						alt='logo'
						className='social-icon'
					/>
				</a>
				<a
					href='https://twitter.com/MemoryzeHQ'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.twitter}
						alt='logo'
						className='social-icon'
					/>
				</a>
				<a
					href='https://vm.tiktok.com/ZMdyGJAUA/'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.tiktok}
						alt='logo'
						className='social-icon'
					/>
				</a>
			</div>
			<p
				style={{
					fontWeight: 'bold',
					fontSize: 0.75 + 'rem',
					marginTop: 30 + 'px',
				}}>
				<span style={{ fontSize: 1 + 'rem', paddingTop: 75 + 'px' }}>
					&copy;
				</span>{' '}
				Memoryze, 2021. All rights reserved
			</p>
			<p>
				<a href='/terms' className='footer-link'>
					Terms of services{' '}
				</a>
				|{' '}
				<a href='/privacy-policy' className='footer-link'>
					Privacy Policy
				</a>
			</p>
		</footer>
	);
}

export default Footer;
