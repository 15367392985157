
export const maxSummaryChars = 300;
export const maxSummaryListLength = 8;
export const maxBioChars = 228;
export const maxLessonTitleChars = 70;
export const maxCourseTitleChars = 83;
export const maxCourseDescChars = 370;
export const lessonDescChars = 180;
export const activeOpacity = 0.6;
export const allowedFileExtensions = ['jpg', 'png', 'jpeg', 'heic', 'pdf'];
export const thirtyThousand = 30000;

export const DEFAULT_PAGINATION_COUNT = 10;
export const PAGINATION_INCREMENT = DEFAULT_PAGINATION_COUNT / 2;

export enum ToasterDuration {
	SHORT = 3000,
	LONG = 6000,
}
