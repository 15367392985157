import React from 'react';
import { Box } from '@mui/material';
import { IAudio } from '../../types/interfaces';
import ResourceListItem from './ResourceListItem';

interface IProps {
	lessons: IAudio[] | null;
}

export default function Lessons({ lessons }: IProps) {
	return (
		<Box
			sx={{
				width: '95%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				margin: 'auto',
				paddingY: 3,
				minHeight: '100%',
			}}>
			{lessons?.map((lesson, i) => (
				<ResourceListItem
					index={i}
					title={lesson.title}
					description={lesson.description}
					key={i} // You may want to use a more unique key if available
				/>
			))}
		</Box>
	);
}
