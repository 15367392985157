import React from 'react';
import { Box, Typography } from '@mui/material';
import { connect } from 'react-redux';
import SearchListItem from './SearchListItem';
import {
	ISearchHistoryData,
	ISearchedCourse,
	ISearchedEducator,
	IStore,
	SearchDataType,
} from '../../types/interfaces';

const mapStateToProps = ({ searchHistory }: IStore) => {
	return {
		searchHistory,
	};
};

interface IProps {
	searchHistory: ISearchHistoryData[];
}

function RecentlySearched({ searchHistory }: IProps) {
	const showEmptyText = searchHistory.length === 0;
	return (
		<Box
			sx={{
				flexDirection: 'column',
				padding: 2,
				width: '100%',
				flex: 1,
			}}>
			{showEmptyText && (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
                        flexDirection: "column",
						alignItems: 'center',
						justifyContent: 'center',
						paddingBottom: 25, // Adjust to 200px (Material-UI uses spacing based on a factor of 8)
					}}>
					<Typography
						sx={{
							textAlign: 'center',
							fontSize: 20,
							fontWeight: 500,
						}}>
						Embark on a learning adventure
					</Typography>
					<Typography
						sx={{
							textAlign: 'center',
							fontSize: 16,
							fontFamily: 'Manrope-Light',
							marginTop: 2, // Adjust to 15px marginTop
						}}>
						Search for educators or explore curated courses
					</Typography>
				</Box>
			)}
			{!showEmptyText && (
				<Typography
					sx={{
						fontSize: 20,
						fontWeight: 500,
					}}>
					Recently Searched
				</Typography>
			)}

			{searchHistory.map((item, i) => {
				return (
					<SearchListItem
						data={
							item.type === SearchDataType.COURSE
								? (item.data as ISearchedCourse).course
								: (item.data as ISearchedEducator).educator
						}
						dataType={item.type}
						key={'prevSearchedCourse' + i}
						enableItemCancelFeature={true}
						searchedID={item.data.id}
					/>
				);
			})}
		</Box>
	);
}

export default connect(mapStateToProps, null)(RecentlySearched);
