import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { ILink } from '../../types/interfaces';

interface IProps {
	links: ILink[];
}

export default function LinksDisplay({ links }: IProps) {
	const handlePrivacyPolicyLink = (link: string) => {
		window.open(link, '_blank');
	};

	const LinkReadOnly = ({ link }: { link: ILink }) => (
		<Box sx={styles.box}>
			<Typography sx={styles.labelText} noWrap>
				{link.label}
			</Typography>
			<Link
				sx={styles.linkText}
				onClick={() => handlePrivacyPolicyLink(link.link)}
				noWrap>
				{link.link}
			</Link>
		</Box>
	);

	return (
		<Box
			sx={{
				display: links?.length < 1 ? 'none' : 'flex',
				flexDirection: 'column',
				paddingBottom: 1,
			}}>
			{links?.map((link, i) => (
				<LinkReadOnly link={link} key={i} />
			))}
		</Box>
	);
}

const styles = {
	box: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingTop: 1,
		paddingBottom: 1,
	},
	labelText: {
		fontSize: 14,
		width: '30%',
		paddingRight: 1,
		color: '#000',
	},
	linkText: {
		fontSize: 14,
		flex: 1,
		color: '#007bff', // MUI default link color
		textDecoration: 'underline',
		cursor: 'pointer',
	},
};
