import React from 'react';
import { motion } from 'framer-motion';

const fadeInVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.5 } },
};

const FadeInSection = ({ children }: any) => {
	return (
		<motion.section
			initial='hidden'
			animate='visible'
			variants={fadeInVariants}>
			{children}
		</motion.section>
	);
};

export default FadeInSection;
