// Use localhost for development
export const devDomain = 'https://da29-23-92-15-66.ngrok-free.app';

// Check if the environment is development
export const isDevelopment = process.env.REACT_APP_ENV === 'development';

// API_BASE_URL and API_KEY variables are being retrieved from environment variables
export const API_BASE_URL = isDevelopment
    ? devDomain // Use the development domain for local development
    : process.env.REACT_APP_PROD_DOMAIN; // Production domain

export const API_KEY = isDevelopment
    ? '' // No API key for development
    : process.env.REACT_APP_API_KEY ?? ''; // Production API key


export const REFRESH_URL = '/users/token/refresh';
export const SIGN_UP_URL = '/users/create_user';
export const LOGIN_URL = '/users/token';
export const AUDIO_URL = '/audios/';
export const BULK_AUDIO_CREATE_UPDATE_URL = '/bulk-create-update-audio/';
export const BULK_RESOURCE_CREATE_UPDATE_URL = '/bulk-create-update-resource/';
export const FORGOT_PASSWORD_URL = '/users/forgot_password';
export const EMAIL_VERIFICATION_URL = '/users/verify_user';
export const RESEND_CODE_URL = '/users/resend_code';
export const GET_ALL_COURSES_URL = '/courses/';
export const GET_LIVE_COURSES_URL = '/live-courses-list';
export const USERS_URL = '/users/users/';
export const GET_COURSES_BY_CATEGORY_URL = '/courses-by-category/';
export const LIKES_URL = '/likes/';
export const SAVED_COURSES_URL = '/saved-courses/';
export const PLAYED_COURSES_URL = '/played-courses/';
export const SEARCHED_COURSES_URL = '/searched-courses/';
export const SEARCHED_EDUCATORS_URL = '/users/searched-educators/';
export const SEARCHED_AUDIOS_URL = '/searched-audios/';
export const INCREMENT_COURSE_LISTEN_TIME_URL =
	'/increment-course-listen-time/';
export const DOWNLOADED_COURSES_URL = '/downloaded-courses';
export const CUSTOM_SAVED_COURSES_URL = '/custom-saved-courses';
export const ADD_LESSON_TO_DOWNLOADS_URL = '/add-lesson-to-downloads';
export const REMOVE_LESSON_FROM_DOWNLOADS_URL = '/remove-lesson-from-downloads';
export const CUSTOM_PLAYED_COURSES_URL = '/custom-played-courses';
export const GET_COMBINED_SEARCH_HISTORY_URL =
	'/users/get-combined-searched-data';
export const GET_COMBINED_SEARCH_RESULT_URL =
	'/users/get-combined-search-result';

export const CHECK_USERNAME_URL = '/users/check_username';
export const CHECK_EMAIL_URL = '/users/check_email';
export const RESET_PASSWORD_URL = '/users/reset_password';
export const EDUCATOR_REGISTRATION_URL = '/users/educator_registration/';
export const FEEDBACK_URL = '/users/feedback/';
export const MEMBERSHIPS_URL = '/users/memberships/';
export const SUBSCRIPTIONS_URL = '/users/subscriptions/';
export const MEMBERS_URL = '/users/members/';
export const CREATE_STRIPE_SUBSCRIPTION_URL = '/users/stripe/create-subscription';
export const DEFAULT_PAYMENT_METHOD_URL = '/users/stripe/default-payment-method';
export const UPDATE_DEFAULT_PAYMENT_METHOD_URL = '/users/stripe/update-default-payment-method';
export const SUBSCRIBED_EDUCATORS_URL = '/users/subscribed-educators';
export const STRIPE_CREATE_UPDATE_ACCOUNT_LINK_URL = '/users/stripe/create-update-account-link';
export const CANCEL_SUBSCRIPTION_URL = '/users/cancel-subscription';
// https://expo.dev/pricing => EAS
// About EAS and Expo app secrets => https://docs.expo.dev/build-reference/variables/
