import React, { useState } from 'react';
export const images = {
	semicircleBackground: '/images/vectors/landingPage/semicircleBackground.png', // Direct path
	manStudying: '/images/vectors/landingPage/manStudying.png', // Direct path
	PhoneMockup: '/images/vectors/landingPage/PhoneMockup.png', // Direct path
	Rockstar: '/images/vectors/landingPage/Rockstar.png', // Direct path
};

import { featuresData, memojies } from './data';
import FadeInSection from '../Shared/FadeInSection';
import ScalableSection from '../Shared/ScalableSection';
import './LandingView.css';
import Button from '@mui/material/Button';

function LandingView() {
	const [features] = useState(featuresData);
	const mappedFeatures = features.map(({ name, description, image }, i) => {
		return (
			<div className='feature-contents' key={i + name}>
				<img src={image} alt={name + ' icon'} className='feature-image' />
				<div className='feature-texts'>
					<h5 className='feature-name'>{name}</h5>
					<p className='feature-description'>{description}</p>
				</div>
			</div>
		);
	});
	return (
		<main className='home-container'>
			<img
				src={images.semicircleBackground}
				alt='semicircle background'
				className='semicircle-background'
			/>
			<FadeInSection>
				<section className='section-container landing-section'>
					<div className='pitch'>
						<h3 className='pitch-heading'>
							Audio learning for wherever, whenever
						</h3>
						<p className='pitch-paragraph'>
							{`Learn something new everyday. The best part is you don't have to
							put a pause on whatever you're doing to learn. Just select what
							you want to learn, connect your headphones and press play!`}
						</p>
						<Button
							variant='outlined'
							color='primary'
							className='get-the-app-btn'
							onClick={() => {
								//todo
							}}>
							Get Early Access!
						</Button>
					</div>
					<img
						src={images.manStudying}
						alt='man-studying'
						className='top-image'
					/>
				</section>
			</FadeInSection>
			<ScalableSection scaleStart={0.8} scaleEnd={1.4}>
				<h4 className='features-heading'>Features</h4>
				<section className='section-container'>
					<div className='features'>
						<div className='feature-contents-container'>{mappedFeatures}</div>
					</div>
					<img
						src={images.PhoneMockup}
						alt='phone mockup'
						className='phone-mockup'
					/>
				</section>
			</ScalableSection>
			<ScalableSection scaleStart={0.7} scaleEnd={1.4}>
				<h4 className='section-heading sake-of-learning-header'>
					Learn for the sake of learning
				</h4>
				<section className='section-container rock-star-section'>
					<img src={images.Rockstar} alt='rock star' className='rock-star' />
					<div className='rock-star-text'>
						<h5 className='rock-star-heading'>Live and learn</h5>
						<p className='rock-star-description'>{`Memoryze is a learning app designed to fit right in with your busy (and maybe not-so-busy) schedules. Audio learning takes the bore out of your daily routine and makes it 10x times more productive. 
					You get to learn whatever you feel like learning at your own pace, and it requires little effort - just listen, learn and have fun.`}</p>
						<a href='/wait-list' className='get-the-app-btn'>
							Get early access!
						</a>
					</div>
				</section>
			</ScalableSection>
			<ScalableSection>
				<h4 className='section-heading memoji-section-header'>
					Hundreds of people have signup up for early access
				</h4>
				<p className='section-subheading'>
					{`Here's what some of them had to say`}
				</p>
				<section className='section-container memoji-section'>
					{memojies.map(({ name, image, comment }, i) => {
						return (
							<div className='memoji-card' key={i + name}>
								<img src={image} alt='a memoji' className='memoji' />
								<div className='memoji-texts-container'>
									<p className='memoji-name'>{name}</p>
									<p className='memoji-comment'>{comment}</p>
								</div>
							</div>
						);
					})}
				</section>
			</ScalableSection>
		</main>
	);
}

export default LandingView;
