import React from 'react';

function PrivacyPolicy() {
	return (
		<main className='home-container container tp'>
			<h1 className='c10' id='h.gjdgxs'>
				<span className='c5'>
					<br />
					Privacy Policy
				</span>
			</h1>
			<p className='c3'>
				<span className='c0'>Last Updated: May 10, 2021</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					The Memoryze.co website and subdomains, mobile applications, and other
					media including all of their respective features and content
					(collectively, the “
				</span>
				<span className='c5 c6'>Site</span>
				<span className='c0'>
					”) is a service made available by Memoryze (“
				</span>
				<span className='c6 c5'>Memoryze</span>
				<span className='c0'>”, “</span>
				<span className='c6 c5'>we</span>
				<span className='c0'>” or “</span>
				<span className='c6 c5'>our</span>
				<span className='c0'>”).</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>HOW THIS PRIVACY POLICY APPLIES</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					Your privacy is very important to us, and in order to best protect
					your information, we have adopted this privacy policy (“
				</span>
				<span className='c6 c5'>Privacy Policy</span>
				<span className='c0'>
					”), which applies to any and all information each visitor or user of
					the Site (each, a “
				</span>
				<span className='c6 c5'>User</span>
				<span className='c0'>”, “</span>
				<span className='c6 c5'>you</span>
				<span className='c0'>” or “</span>
				<span className='c6 c5'>your</span>
				<span className='c0'>
					”) submit to us in connection with your access to and/or use of the
					Site. This Privacy Policy describes the information we collect from
					you through the Site, how we use that information, and our legal basis
					for doing so. It also covers whether and how that information may be
					shared and your rights and choices regarding the information you
					provide to us. It also covers whether and how that information may be
					shared and your rights and choices regarding the information you
					provide to us. Capitalized words not defined in this Privacy Policy
					are defined in our&nbsp;
				</span>
				<span className='c8 c13'>
					<a className='c16' href='/terms'>
						Terms of and Conditions of Use
					</a>
				</span>
				<span className='c0'>&nbsp;(“</span>
				<span className='c6 c5'>Terms of Service</span>
				<span className='c0'>”).</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					By using the Site, you are accepting the practices described in this
					Privacy Policy (including new versions of this Privacy Policy when and
					as they go into effect), and the Terms of Use, which governs this
					Privacy Policy and contains all disclaimers of warranties and
					limitation of liabilities.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>WHAT INFORMATION WE COLLECT AND RECEIVE</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					In order for you to use our Site, we need to collect and process
					certain information, which depending on how you use the Site, may
					include:
				</span>
			</p>
			<ul className='c1 lst-kix_list_1-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						Information you provide by using the Site, such as by registering
						and creating an Account and completing forms on the Site, or by
						posting User Content in the Interactive Areas (as such terms are
						defined in the Terms of Use), such as your first and last name,
						email address, mailing address, or telephone number. We may also ask
						for this information if you subscribe to our newsletter, complete
						surveys, or contact us;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Details of any requests or transactions you make through the Site.
						We partner with Stripe for payment processing and the credit card
						and other payment information you submit is collected and used by
						them in accordance with their privacy policy available at
						https://stripe.com/en-US/privacy;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Information about your activity on and interaction with Memoryze,
						such as your Internet Protocol (IP) address, the type of device or
						browser you use to access the Site, and usage data such as time
						spent on and your actions on the Site;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c14 c17'>
						Information about the ways people visit and interact with our Site
						in the form of web traffic analytics. We also work with (or may in
						the future work with) third parties such as Google Analytics and
						Mixpanel to provide analytics services. You can opt out of being
						included in Google Analytics&nbsp;
					</span>
					<span className='c8'>
						<a
							className='c16'
							href='https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1622075127594000&amp;usg=AOvVaw1O3nVwIhkEafnezVF68ocU'>
							here
						</a>
					</span>
					<span className='c0'>
						. For more information about our use of data analytics to protect
						and improve our Site, see Cookies and How We Utilize Them below; and
					</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>
						Communications you send to us, such as when you request support,
						send us comments or questions, or report a problem to us.
					</span>
				</li>
			</ul>
			<p className='c3'>
				<span className='c0'>
					We also collect and maintain aggregated, anonymized, or pseudonymized
					information to protect the safety and security of our Site, improve
					our Site, or comply with legal obligations.
				</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					You may decline to provide us with some or all of your information.
					However, this will limit your ability to use our Site.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>COOKIES AND HOW WE UTILIZE THEM</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					We use the following types of cookies and other technologies:
				</span>
			</p>
			<ul className='c1 lst-kix_list_2-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						Security: We secure the Site with cookies that facilitate security
						features and allow us to detect activity that might violate our
						rules and Terms of Use, such as unauthorized access or activity.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Localization: Some cookies help us provide localized experiences,
						such as displaying the Site in the local language.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Site Features and Services: Certain cookies provide greater
						functionality to the Site and help us deliver our products and
						services.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Performance: We use performance cookies to help us route traffic
						between servers and understand how the Site is performing so we can
						provide users with the best experience possible.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c14 c17'>
						Analytics and Research: Cookies and other technologies also help us
						to understand, improve, and research features and content on the
						Site, such as by using Google Analytics, which you can opt out
						of&nbsp;
					</span>
					<span className='c8'>
						<a
							className='c16'
							href='https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1622075127596000&amp;usg=AOvVaw2N058Cf-pR-pq_XMIzr7ON'>
							here
						</a>
					</span>
					<span className='c0'>.</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c14 c17'>
						Marketing and Online Behavioral Advertising: Cookies help us deliver
						targeted advertisements on social media platforms based on your
						activity on the Site and to track the performance of those ads. In
						some cases, our partners may use cookies to provide us with
						information about your interactions with their services. Those
						third-party cookies would be subject to such third-party service’s
						privacy policies. The Self-Regulatory Program for Online Behavioral
						Advertising program provides consumers with the ability to opt-out
						of having their online behavior recorded and used for advertising
						purposes. To opt out of having your online behavior collected for
						advertising purposes,&nbsp;
					</span>
					<span className='c8'>
						<a
							className='c16'
							href='https://www.google.com/url?q=http://optout.aboutads.info/&amp;sa=D&amp;source=editors&amp;ust=1622075127597000&amp;usg=AOvVaw2-EKOjpOKKxrXRyoy0afBz'>
							click here
						</a>
					</span>
					<span className='c0'>.</span>
				</li>
			</ul>
			<h2 className='c4'>
				<span className='c5'>
					LIMITING OR BLOCKING COOKIES AND OUR DO NOT TRACK POLICY
				</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					Your browser may give you the ability to control cookies, which may
					depend on the type of cookie, and browsers can be set to reject
					browser cookies. However, blocking or deleting cookies may prevent you
					from using most of the Site.
				</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					Some browsers have “do not track” features that allow you to tell a
					website not to track you. These features are not all uniform. If you
					block cookies, certain features on the Site may not work. If you block
					or reject cookies, not all of the tracking described here will stop.
				</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					Note that certain options you select are browser- and device-specific.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>HOW WE USE THE INFORMATION WE COLLECT</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					We use the information we collect for the following purposes:
				</span>
			</p>
			<ul className='c1 lst-kix_list_3-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>To protect the Site;</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						To enable us to provide you with the Site, and to improve and
						promote the Site and our services;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						To contact you and customize your experience on the Site; and
					</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>
						To track and analyze use of the Site and our services so that we can
						improve how Memoryze is performing and provide our users with the
						best experience possible.
					</span>
				</li>
			</ul>
			<h2 className='c4'>
				<span className='c5'>EUROPEAN UNION USERS</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					European Union data protection law requires a “lawful basis” for
					collecting and retaining personal information from citizens or
					residents of the European Economic Area. Our lawful bases include:
				</span>
			</p>
			<ul className='c1 lst-kix_list_4-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c5 c17'>Consent:</span>
					<span className='c0'>
						&nbsp;In certain cases, you have affirmatively provided us with your
						consent to collect your personal data for a specific purpose, such
						as providing your name and email address when you register an
						Account or sign up for our newsletter.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c5 c17'>Contract:</span>
					<span className='c0'>
						&nbsp;We may need your personal data to comply with our contractual
						obligation to deliver the Site, such as fulfilling subscriptions or
						transactions.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c5 c17'>Legal Obligations:</span>
					<span className='c0'>
						&nbsp;Sometimes the law requires us to collect and use your data,
						such as applicable tax laws may require us to retain records of
						payments made through the Site.
					</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c5 c17'>Legitimate Interests:</span>
					<span className='c0'>
						&nbsp;This is a technical term that means we have a good and fair
						reason to use your data and we do so in ways which do not harm your
						interests and rights, such as to pursue our legitimate interests in
						a way that might reasonably be expected as part of running our
						business and that does not materially impact your rights, freedom,
						or interests. For example, we use identity, device, and location
						information to prevent fraud and abuse and to keep the Site secure,
						may also send you promotional communications about the Site and
						services subject to your right to opt out, analyze how users
						interact with the Site so we can understand better what elements do
						or do not work well, which allows us to improve and develop the
						quality of the online experience we offer all our Users.
					</span>
				</li>
			</ul>
			<h2 className='c4'>
				<span className='c5'>HOW WE SHARE THE INFORMATION WE COLLECT</span>
			</h2>
			<h3 className='c10'>
				<span className='c14 c13 c19'>
					Information that May be Publicly Accessible
				</span>
			</h3>
			<p className='c3'>
				<span className='c0'>
					If you post User Content in Interactive Areas, other Users will be
					able to view such User Content, along with your name and email address
					that you provide in posting the User Content to the Interactive Areas.
					Do not post to Interactive Areas any information, including, without
					limitation, personal information, that you do not want to be publicly
					accessible.
				</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					Additionally, personal information in your Account profile is shared
					or otherwise viewable by other Users unless you specifically choose
					the option within your account for your profile to be anonymous. Bear
					in mind that if you wish to remain anonymous, you may not be able to
					take advantage of some of the functionality of the Site.
				</span>
			</p>
			<h3 className='c10'>
				<span className='c14'>Information We Never Share Publicly</span>
			</h3>
			<p className='c3'>
				<span className='c0'>
					The following personal information is never publicly displayed or
					revealed to other Users (unless you post it in the Interactive Areas
					or share it in your public Account profile):
				</span>
			</p>
			<ul className='c1 lst-kix_list_5-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>Your name;</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						The date you register for any services we provide through the Site,
						such as our newsletter/mailing list;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Your email address, IP address, mailing address, or phone number;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Any other identity verification information or documentation;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>Any payment information you provide; and</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>Communications you send to us.</span>
				</li>
			</ul>
			<h3 className='c10'>
				<span className='c14'>
					Information We Share with Trusted Third-Party Service Providers
				</span>
			</h3>
			<p className='c3'>
				<span className='c0'>
					We may share certain information pertaining to you with certain
					trusted third-party service providers to help us provide, improve,
					promote, or protect the Site, such as when we partner with payment
					processors or use services to help us manage our ads on other
					websites. When we share data with third-party services to help us
					deliver the Site and our services, we require those service providers
					to use your information only for the purposes we authorized and to
					protect your personal information at least to the same standards we
					do. We may also share information that is aggregated and anonymized in
					a way that it does not directly identify you.
				</span>
			</p>
			<h3 className='c10'>
				<span className='c14'>
					Information We Share to Protect Memoryze and Comply with the Law
				</span>
			</h3>
			<p className='c3'>
				<span className='c0'>
					We reserve the right to disclose information we collect from or about
					you when we believe that doing so is reasonably necessary to comply
					with the law or law enforcement, to prevent fraud or abuse, or to
					protect Memoryze’s legal rights, property, or the safety of Memoryze,
					its Users, or others. You hereby authorize us to share or disclose
					your personal information, including, without limitation, any content,
					records, or electronic communications of any kind, when we determine,
					in our sole discretion, that the disclosure of such information is
					necessary to identify, contact, or bring legal action against you if:
				</span>
			</p>
			<ul className='c1 lst-kix_list_6-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						You are or may be violating the Terms of Use or this Privacy Policy;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						You are interfering with the rights or property of Memoryze or a
						third party;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						You are violating any applicable law, rule or regulation;
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Necessary or required by any applicable law, rule or regulation; or
					</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>
						Requested by governmental authorities in the event of any
						investigation, act of terrorism or instance of local, regional or
						national emergency.
					</span>
				</li>
			</ul>
			<p className='c3'>
				<span className='c0'>
					In the event Memoryze is acquired by or merged with a third-party
					entity or sells all or substantially all of its assets, we reserve the
					right, in any of these circumstances, to transfer or assign the
					information and content we have collected from Users as part of such
					merger, acquisition, sale or other change of control. In the unlikely
					event of our bankruptcy, insolvency, reorganization, receivership or
					assignment for the benefit of creditors, or the application of laws or
					equitable principles affecting creditors’ rights generally, we may not
					be able to control how your personal information is treated,
					transferred or used.
				</span>
			</p>
			<h3 className='c10'>
				<span className='c14'>Links to Other Websites and Services</span>
			</h3>
			<p className='c3'>
				<span className='c0'>
					Our Site may include links to other websites or services whose privacy
					practices may differ from Memoryze’s privacy practices as described in
					this Privacy Policy. When you click or otherwise use a link to an
					external website or service, the privacy policy and data processing
					disclosures for that website or service govern.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>DATA RETENTION</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					We will retain the information we collect from or about you as long as
					necessary to provide you with the Site or our services, or as
					otherwise set forth in this Privacy Policy, and to the extent set out
					in this Privacy Policy and as necessary to comply with our legal
					obligations, resolve disputes, enforce our agreements and protect
					Memoryze’s legal rights. Additionally, we may retain indefinitely any
					aggregated, anonymized or pseudonymized information which we may
					collect and maintain.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>CROSS-BORDER DATA TRANSFERS</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					Memoryze is based in the United States so your information will be
					collected and processed in the United States, which has its own laws
					governing data protection and access to it, including by the
					government. If you reside outside of the United States, be advised
					that the rules that protect your personal information under United
					States federal, state and local law may differ from those of your home
					country.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>CHILDREN’S PRIVACY</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					The privacy of children is of the utmost importance, and we are
					committed to complying with the Children’s Online Privacy Protection
					Act (COPPA). This Site does not knowingly collect, use, or disclose
					personal information from children under the age of 13. If we ever
					discover that we have inadvertently collected personal information
					from children under the age of 13, we will delete it as soon as
					possible. If you are a parent or legal guardian and believe that we
					have collected personal information from your child under the age of
					13, please email us at hello@Memoryze.co, and we will take steps to
					delete such personal information as soon as possible.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>ACCESSING YOUR INFORMATION</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					Users residing in certain countries, including citizens or residents
					of the European Economic Area, are afforded certain rights regarding
					their personal information. Except where an exception or exemption
					applies, these rights include the ability to access, correct, and
					request deletion of such user’s personal information. Even after you
					request we delete your personal information, we may retain certain
					information as required by law or as necessary for our legitimate
					business purposes.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>EMAIL NOTIFICATIONS</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					We want to communicate with you only if you want to hear from us. We
					will send you email relating to your transactions with us. You may
					also elect to receive certain marketing email communications, in
					accordance with your preferences, and from which you may opt out at
					any time by adjusting your notification settings. We will also send
					you service-related announcements when and as necessary. We try to
					keep emails to a minimum and give you the ability to opt in to any
					marketing communications we send.
				</span>
			</p>
			<p className='c3'>
				<span className='c0'>
					We maintain a strict “no-spam” policy. We will not use false or
					misleading subjects or email addresses, identify the message as an
					advertisement in a reasonable way, include the physical address of our
					business, monitor our third-party email marketing services for
					compliance, honor opt-out/unsubscribe requests quickly, and allow
					users to unsubscribe by using the link at the bottom of each email. If
					you are having problems unsubscribing please contact us at
					hello@Memoryze.co by forwarding the newsletter, if applicable, and
					including the word “Unsubscribe” in the subject line and we will use
					reasonable efforts to remove you within seven (7) business days.
					Please note that we will not process any unsubscribe requests
					submitted as direct replies to any newsletter.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>SECURITY</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					We take security seriously, and the security of your personal data is
					important to us. We follow industry-standard practices to protect the
					data we collect and maintain, including using Secure Sockets Layer
					(SSL) to encrypt information as it travels over the Internet. No
					method of transmission over the Internet or electronic storage is
					completely secure, so we cannot guarantee its absolute security.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>UPDATES TO THIS PRIVACY POLICY</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					We may change the terms of this Privacy Policy at any time. Whenever
					we make a material change, we will post on the Site or send you an
					email. The effective date of the most recent version of this Privacy
					Policy will appear at the top of this page. New versions of this
					Privacy Policy will never apply retroactively.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>CONTACT</span>
			</h2>
			<p className='c3'>
				<span className='c0'>
					Questions or comments about this Privacy Policy should be sent by
					email to hello@memoryze.co.
				</span>
			</p>
			<p className='c18'>
				<span className='c0'></span>
			</p>
		</main>
	);
}

export default PrivacyPolicy;
