import { Box, Typography, Button, Alert, Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import {
	IStore,
	IUser,
	ToasterType,
	IPaymentMethod,
	IEducatorMembershipData,
} from '../../types/interfaces';
import PaymentMethod from './PaymentMethod';
import { ToasterService } from '../../MainUtils/ToasterService';
import { ToasterDuration } from '../../types/constants';
import { setEducatorMembershipData, setUserData } from '../../store/actions';
import { PaymentService } from '../../api/paymentsService';
import { PaymentMethodDisplay } from './PaymentMethodDisplay';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
	throw new Error('Stripe public key is not defined');
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CheckoutForm({
	membershipId,
	newPaymentMethod,
	setUserData,
	userData,
	onChangePaymentMethod,
	setEducatorMembershipData,
}: {
	membershipId: number;
	newPaymentMethod: IPaymentMethod | null;
	setUserData: (userData: IUser) => void;
	userData: IUser | null;
	onChangePaymentMethod: () => void;
	setEducatorMembershipData: (educatorMembershipData: IEducatorMembershipData | undefined) => void;
}) {
	const navigate = useNavigate();

	const stripe = useStripe();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(
		newPaymentMethod
	);
	useEffect(() => {
		setPaymentMethod(newPaymentMethod);
	}, [newPaymentMethod]);

	useEffect(() => {
		const getDefaultPaymentMethod = async () => {
			if (userData && userData.has_payment_method) {
				try {
					const paymentMethod = await PaymentService.getPaymentMethodData();
					setPaymentMethod(paymentMethod);
				} catch (error) {
					ToasterService.dispatchAddToaster({
						message: 'Error fetching default payment method',
						type: ToasterType.ERROR,
						duration: ToasterDuration.SHORT,
					});
				}
			}
		};
		getDefaultPaymentMethod();
	}, []);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!stripe) {
			return;
		}

		setIsProcessing(true);

		// Send payment method ID to your backend to create the subscription
		// (when the user already has a payment method, we don't need to send the payment method id)
		try {
			const response = await PaymentService.createSubscription({
				membership_id: membershipId.toString(),
				payment_method_id: newPaymentMethod?.id ?? undefined,
				//When the user already has a payment method, we don't need to send the payment method id
			});
			setUserData({
				...response.user,
			});

			// Handle success
			ToasterService.dispatchAddToaster({
				message: 'Successfully subscribed!',
				type: ToasterType.SUCCESS,
				duration: ToasterDuration.SHORT,
			});
			// Reset the educator membership data in the store
			setEducatorMembershipData(undefined);
			// Navigate back to previous page using react-router-dom
			navigate(-1);
		} catch (error: unknown) {
			if (
				error &&
				typeof error === 'object' &&
				'response' in error &&
				error.response &&
				typeof error.response === 'object' &&
				'data' in error.response &&
				error.response.data &&
				typeof error.response.data === 'object' &&
				'error' in error.response.data &&
				typeof error.response.data.error === 'string'
			) {
				setErrorMessage(error.response.data.error);
			} else {
				setErrorMessage('An unexpected error occurred');
			}
		}

		setIsProcessing(false);
	};

	return (
		<form>
			{paymentMethod && (
				<Box sx={{ mb: 3 }}>
					<PaymentMethodDisplay
						paymentMethod={paymentMethod}
						onChangePaymentMethod={onChangePaymentMethod}
					/>
				</Box>
			)}
			{errorMessage && (
				<Alert severity='error' sx={{ mt: 2 }}>
					{errorMessage}
				</Alert>
			)}
			<Button
				onClick={handleSubmit}
				variant='outlined'
				fullWidth
				disabled={!stripe || isProcessing}
				sx={{ mt: 3 }}>
				{isProcessing ? 'Processing...' : 'Subscribe Now'}
			</Button>
		</form>
	);
}

function SubscribeToEducator({
	userData,
	setUserData,
	educatorMembershipData,
	setEducatorMembershipData,
}: {
	userData: IUser | null;
	setUserData: (userData: IUser) => void;
	educatorMembershipData?: IEducatorMembershipData;
	setEducatorMembershipData: (educatorMembershipData: IEducatorMembershipData | undefined) => void;
}) {
	if (!userData) return null;

	const navigate = useNavigate();
	const [newPaymentMethod, setNewPaymentMethod] =
		useState<IPaymentMethod | null>(null);
	const [paymentMethodProcessed, setPaymentMethodProcessed] = useState(
		userData.has_payment_method
	);
	const onContinue = (paymentMethod: IPaymentMethod) => {
		setNewPaymentMethod(paymentMethod);
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					mb: 1,
				}}>
				<Button
					onClick={() => navigate(-1)}
					variant='text'
					sx={{ color: 'primary.main' }}
					startIcon={<ArrowBackIcon />}>
					Back
				</Button>
			</Box>
			<Box sx={{ maxWidth: 600, mx: 'auto', p: 3, backgroundColor: 'white' }}>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 3, justifyContent: 'space-between' }}>
					<Typography component='h1' variant='h5'>
						{!userData.has_payment_method && !paymentMethodProcessed
							? `Add Payment Method to Subscribe to ${educatorMembershipData?.educatorName}`
							: `Subscribe to ${educatorMembershipData?.educatorName}`}
					</Typography>
					<Avatar
						alt={educatorMembershipData?.educatorName}
						src={educatorMembershipData?.educatorProfileImage || '/defaultProfileImage.png'}
						sx={{ width: 50, height: 50 }}
					/>
				</Box>

				<Typography variant='body1' sx={{ mb: 2 }}>
					Monthly subscription fee: ${educatorMembershipData?.membershipAmount}
					/month
				</Typography>

				<Elements
					stripe={stripePromise}
					options={{
						paymentMethodCreation: 'manual',
						mode: 'setup',
						currency: 'usd',
					}}>
					{!paymentMethodProcessed ? (
						<PaymentMethod
							onContinue={onContinue}
							setPaymentMethodProcessed={setPaymentMethodProcessed}
						/>
					) : (
						<>
							<Typography variant='body1' sx={{ mb: 2 }}>
								Payment method processed!
							</Typography>
							<CheckoutForm
								userData={userData}
								membershipId={educatorMembershipData?.membershipId ?? 0}
								newPaymentMethod={newPaymentMethod}
								setUserData={setUserData}
								setEducatorMembershipData={setEducatorMembershipData}
								onChangePaymentMethod={() => {
									setPaymentMethodProcessed(false);
								}}
							/>
						</>
					)}
				</Elements>
			</Box>
		</>
	);
}

const mapStateToProps = (state: IStore) => ({
	userData: state.userData,
	educatorMembershipData: state.educatorMembershipData,
});
const mapDispatchToProps = (dispatch: any) => ({
	setUserData: (userData: IUser) => dispatch(setUserData(userData)),
	setEducatorMembershipData: (educatorMembershipData: IEducatorMembershipData | undefined) => dispatch(setEducatorMembershipData(educatorMembershipData)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubscribeToEducator);
