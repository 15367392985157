import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Link, Alert } from '@mui/material';
import { connect } from 'react-redux';
import { IStore, IUser } from '../../types/interfaces';
import AccountTab from './AccountTab';
import ManageSubscriptionsTab from './ManageSubscriptionsTab';

interface StateProps {
	userData: IUser | null;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`settings-tabpanel-${index}`}
			aria-labelledby={`settings-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function Settings({ userData }: StateProps) {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}>
			<Typography component='h1' variant='h5' sx={{ mb: 3, fontSize: 30 }}>
				Settings
			</Typography>
			<Alert severity='info' sx={{ mt: 1, mb: 2 }}>
				Access more settings on the mobile app.{' '}
				<Link
					component='button'
					variant='body1'
					sx={{ color: '#2F69F8', textDecorationColor: '#2F69F8' }}>
					Open App
				</Link>
			</Alert>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
					<Tabs
						value={selectedTab}
						onChange={handleTabChange}
						aria-label='Settings tabs'>
						<Tab
							label='Account'
							id='settings-tab-0'
							aria-controls='settings-tabpanel-0'
							sx={{ minWidth: 'auto', marginRight: 2 }}
						/>
						<Tab
							label='Subscriptions'
							id='settings-tab-1'
							aria-controls='settings-tabpanel-1'
							sx={{ minWidth: 'auto' }}
						/>
					</Tabs>
				</Box>

				<TabPanel value={selectedTab} index={0}>
					<AccountTab userData={userData} />
				</TabPanel>

				<TabPanel value={selectedTab} index={1}>
					<ManageSubscriptionsTab userData={userData} />
				</TabPanel>
			</Box>
		</Box>
	);
}

const mapStateToProps = (state: IStore): StateProps => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	// Map dispatch actions here
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
