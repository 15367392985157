import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Typography,
	Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	CircularProgress,
} from '@mui/material';
import { IUser, SubscribedEducator, ToasterType } from '../../types/interfaces';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../../api/profileService';
import { ToasterDuration } from '../../types/constants';
import { ToasterService } from '../../MainUtils/ToasterService';

interface StateProps {
	userData: IUser | null;
}

const EducatorListItem = ({
	educator,
	setSubscribedEducators,
}: {
	educator: IUser;
	setSubscribedEducators: (subscribedEducators: IUser[]) => void;
}) => {
	const [activeSubscriptionId, setActiveSubscriptionId] = useState<
		number | null
	>(null);
	const navigate = useNavigate();

	const handleSelect = async () => {
		navigate(`/app/educators/${educator.id}`);
	};

	const handleCancel = async () => {
		try {
			const subscribedEducators = await ProfileService.cancelSubscription(
				activeSubscriptionId as number
			);
			setSubscribedEducators(subscribedEducators);
			ToasterService.dispatchAddToaster({
				message: 'Subscription cancelled successfully',
				type: ToasterType.SUCCESS,
				duration: ToasterDuration.LONG,
			});
		} catch (error) {
			ToasterService.dispatchAddToaster({
				message: 'Failed to cancel subscription',
				type: ToasterType.ERROR,
				duration: ToasterDuration.LONG,
			});
		}
	};

	const [openDialog, setOpenDialog] = useState(false);

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	const handleDialogConfirm = (e: React.MouseEvent) => {
		e.stopPropagation(); // Prevent parent click
		handleCancel();
		setOpenDialog(false);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				height: 60,
				width: '100%',
				marginTop: 2,
				paddingX: 1,
				cursor: 'pointer',
				'&:hover': { backgroundColor: '#f0f0f0' },
			}}>
			<Avatar
				src={
					educator.profile_image || '/images/defaultEducatorProfileImage.png'
				}
				alt={educator.name}
				sx={{ width: 60, height: 60, borderRadius: 2, marginRight: 2 }}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					flex: 1,
				}}>
				<Box
					onClick={handleSelect}
					sx={{
						flex: 1,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						width: 160,
					}}>
					<Typography variant='body1' noWrap color='textSecondary'>
						{educator.name}
					</Typography>
				</Box>

				<Link
					variant='body1'
					component='button'
					onClick={() => {
						setOpenDialog(true);
						setActiveSubscriptionId(educator.subscription_id);
					}}
					sx={{
						cursor: 'pointer',
						fontWeight: 600,
						minWidth: 'fit-content',
						marginLeft: 2,
						zIndex: 1000,
					}}>
					Cancel
				</Link>
			</div>
			<Dialog 
				open={openDialog} 
				onClose={handleDialogClose}
				maxWidth={false}
				PaperProps={{
					sx: { maxWidth: 300 }
				}}
			>
				<DialogTitle>Cancel Subscription</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<strong>Are you sure you want to cancel your subscription to <span style={{color: '#1976d2', fontWeight: 700}}>{educator.name}</span></strong>
						? If you cancel, you will lose access to their courses and updates.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>No</Button>
					<Button onClick={handleDialogConfirm} color='error'>
						Yes, Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

function ManageSubscriptionsTab({ userData }: StateProps) {
	const [subscribedEducators, setSubscribedEducators] = useState<
		IUser[]
	>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		ProfileService.loadSubscribedEducators()
			.then((educators) => {
				setSubscribedEducators(educators);
			})
			.finally(() => setLoading(false));
	}, [userData]);

	if (loading)
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: 200,
				}}>
				<CircularProgress />
			</Box>
		);

	if (subscribedEducators.length === 0) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 4 }}>
				<Typography variant="h6" color="textSecondary">
					You don't have any active subscriptions
				</Typography>
				<Typography variant="body1" color="textSecondary" >
					Subscribe to educators to access their exclusive content and updates
				</Typography>
			</Box>
		);
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
			<Box>
				<Typography variant='h6' sx={{ mb: 2 }}>
					Your Subscriptions
				</Typography>
				{subscribedEducators.map((educator) => (
					<EducatorListItem
						educator={educator}
						key={educator.id}
						setSubscribedEducators={setSubscribedEducators}
					/>
				))}
			</Box>
		</Box>
	);
}

export default ManageSubscriptionsTab;
