export const featuresData = [
	{
		name: 'Topics',
		description:
			'Learn anything, ranging from languages to psychology hacks to design. Audio is limitless.',
		image: '/images/vectors/landingPage/Topics.png', // Direct path
	},
	{
		name: 'Quizzes',
		description:
			'Get quizzed at the end of each lesson to check how well you paid attention.',
		image: '/images/vectors/landingPage/Quizzes.png', // Direct path
	},
	{
		name: 'Educators',
		description:
			'Courses are created and taught by vetted educators from across the globe.',
		image: '/images/vectors/landingPage/Educators.png', // Direct path
	},
	{
		name: 'Community',
		description: 'Meet other learners with the same interest as you.',
		image: '/images/vectors/landingPage/Community.png', // Direct path
	},
	{
		name: 'Offline Use',
		description:
			"Download lessons so you can listen to them even when you're not connected to the internet.",
		image: '/images/vectors/landingPage/OfflineUse.png', // Direct path
	},
	{
		name: 'Resources',
		description: 'Courses come with short PDF materials as learning aids.',
		image: '/images/vectors/landingPage/Resources.png', // Direct path
	},
];

export const memojies = [
	{
		image: '/images/vectors/landingPage/memojies/redHairGirl.png', // Direct path
		name: 'Treasure',
		comment:
			"When I learned about Memoryze, I was excited. Don't get me wrong books are amazing but scouring through law books can be a dreadful task and I love processes that make life easier for me. With Memoryze, I would be able to listen to lessons while multitasking other activities. What a fun practicable way to learn!",
	},
	{
		image: '/images/vectors/landingPage/memojies/dreadGuy.png', // Direct path
		name: 'Pedro',
		comment:
			'Every morning, I spend 3 hours on the road to get to work every night, I spend another 3 hours to get home, courtesy of Lagos traffic. Using those 6 hours to learn something new would make my daily commute much more better. All I have to do is open the app, connect my Airpods and listen.',
	},
	{
		image: '/images/vectors/landingPage/memojies/beanieGirl.png', // Direct path
		name: 'Mariam',
		comment:
			"Repetition is needed to remember what you've learnt. As a student with plethora of courses, studying hasn't been easy and I didn't have a solution to this problem of memorizing until i heard about Memoryze. Memoryze is exactly what we need as students to make memorizing easy.",
	},
	{
		image: '/images/vectors/landingPage/memojies/fadeCutBoy.png', // Direct path
		name: 'Dumebi',
		comment:
			'The main thing about Memoryze that captured my attention was the inclusion of audio to my study. I think "audio learning" is a very viable yet untapped method of education. It removes the bore of long study hours and enhances retention. Kinda similar to how we remember the lyrics to our fav songs because we\'ve listened to them over and over again.',
	},
	{
		image: '/images/vectors/landingPage/memojies/beanieBoy.png', // Direct path
		name: 'Bisuga',
		comment:
			"Memoryze is probably the best educational app I've come across (and I've seen quite a lot). You mean I can learn without needing to sit and stare at a book for hours? Also, it's easy to use and accessible whenever need be. I'm a big big fan.",
	},
	{
		image: '/images/vectors/landingPage/memojies/multicoloredHairGirl.png', // Direct path
		name: 'Amy',
		comment:
			"I've always wanted to learn another language but my excuse has always been lack of time. In my defence, i run my own small bakery where I'm both baker and manager. I listen to music a lot while working so I figure occasionally swapping music for a course that helps me achieve my learning goals is a good move.",
	},
];
