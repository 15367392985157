import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Typography, IconButton } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
	IAudio,
	ICourseListItem,
	IUser,
	SearchDataType,
} from '../../types/interfaces';
import { axiosDefault } from '../../api/axios';
import {
	GET_COMBINED_SEARCH_HISTORY_URL,
	SEARCHED_COURSES_URL,
	SEARCHED_EDUCATORS_URL,
} from '../../api/constants';
import { store } from '../../store/store';
import { setSearchHistory } from '../../store/actions';
import { updateSearchedCourses, updateSearchedEducator } from '../../api/searchService';

export const loadAndStoreSearchHistory = async () => {
	const searchHistory = await axiosDefault(GET_COMBINED_SEARCH_HISTORY_URL);
	store.dispatch(setSearchHistory([...searchHistory?.data]));
};
export const deleteSearchedEducator = async (educatorId) => {
	await axiosDefault.delete(SEARCHED_EDUCATORS_URL + educatorId);
	loadAndStoreSearchHistory();
};
export const deleteSearchedCourse = async (searchedCourseId) => {
	await axiosDefault.delete(SEARCHED_COURSES_URL + searchedCourseId);
	loadAndStoreSearchHistory();
};
interface IProps {
	enableItemCancelFeature?: boolean; //Used in RecentlySearchedList
	searchedID?: number; //Used in RecentlySearchedList
	dataType: SearchDataType;
	data: IUser | ICourseListItem | IAudio;
}

interface IState {
	image: string;
	searchType: string;
	tutor: string; //educator
	title: string;
}

const SearchListItem = ({
	data,
	dataType,
	enableItemCancelFeature,
}: IProps) => {
	const [state, setState] = useState<IState>({
		image:
			dataType === 'course'
				? data?.['descriptive_image']
				: data?.['profile_image'],
		title: dataType === 'course' ? data['name'] : data['name'],
		tutor: dataType === 'course' ? data['tutor_name'] : data['name'],
		searchType: dataType === 'course' ? 'Course' : 'Educator',
	});
	const navigate = useNavigate();

	useEffect(() => {
		setState({
			image:
				dataType === 'course'
					? data?.['descriptive_image']
					: data?.['profile_image'],
			title: dataType === 'course' ? data['name'] : data['name'],
			tutor: dataType === 'course' ? data['tutor_name'] : data['name'],
			searchType:
				dataType === 'course'
					? 'Course'
					: dataType === 'audio'
					? 'Lesson'
					: 'Educator',
		});
	}, [data, dataType]);

	const handleSelect = async () => {
		if (dataType === 'course') {
			updateSearchedCourses(data?.id);
			navigate(`/app/courses/${data.id}`);
		} else {
            updateSearchedEducator(data?.id);
			navigate(`/app/educators/${data.id}`);
		}
	};

	const handleCancel = (e) => {
		e.stopPropagation(); // Prevent parent click
		if (dataType === 'course') {
			deleteSearchedCourse(data.id);
		} else {
			deleteSearchedEducator(data.id);
		}
	};

	return (
		<Box
			onClick={handleSelect}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				height: 60,
				width: '100%',
				marginTop: 2,
				cursor: 'pointer',
				'&:hover': { backgroundColor: '#f0f0f0' },
			}}>
			<Avatar
				src={state.image || '/images/defaultEducatorProfileImage.png'}
				alt={state.title}
				sx={{ width: 60, height: 60, borderRadius: 2, marginRight: 2 }}
			/>
			<Box sx={{ flex: 1 }}>
				<Typography variant='body1' noWrap sx={{ fontWeight: 600 }}>
					{state.title}
				</Typography>
				<Typography variant='body2' noWrap color='textSecondary'>
					{`${state.searchType} - ${state.tutor}`}
				</Typography>
			</Box>
			{enableItemCancelFeature && (
				<IconButton
					onClick={handleCancel}
					sx={{ marginLeft: 1 }}
					aria-label='delete'>
					<DeleteForeverOutlinedIcon />
				</IconButton>
			)}
		</Box>
	);
};

export default SearchListItem;
