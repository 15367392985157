import React, { useState } from 'react';
import {
	Box,
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import { PaymentService } from '../../api/paymentsService';
import { ToasterService } from '../../MainUtils/ToasterService';
import { ToasterDuration } from '../../types/constants';
import { ToasterType, IUser } from '../../types/interfaces';
import { connect } from 'react-redux';
import { IStore } from '../../types/interfaces';

interface Props {
	userData: IUser | null;
}

function StripeConnectAccountUpdate({ userData }: Props) {
	const [loading, setLoading] = useState(false);
	const [openOnboardingModal, setOpenOnboardingModal] = useState(false);

	const createAccountLink = async () => {
		try {
			const accountLink = await PaymentService.getStripeAccountLink();
			return accountLink;
		} catch (error) {
			ToasterService.dispatchAddToaster({
				message: 'Unable to set up payment processing. Please try again later.',
				type: ToasterType.ERROR,
				duration: ToasterDuration.LONG,
			});
			return null;
		}
	};

	const handleConnectStripe = async () => {
		setOpenOnboardingModal(true);
	};

	const handleProceedToOnboarding = async () => {
		setLoading(true);
		const accountLink = await createAccountLink();
		setLoading(false);
		if (!accountLink) return;
		window.location.href = accountLink;
	};

	const hasStripeAccount = userData?.allow_subscriptions;
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
			<Typography variant='h6'>
				{hasStripeAccount ? 'Update Payout Account' : 'Create Payout Account'}
			</Typography>
			<Typography variant='body1'>
				{hasStripeAccount
					? 'Update your Stripe account settings to manage your payout preferences and account information.'
					: "To receive payments from your subscribers, you'll need to create and connect a Stripe account. This will allow you to securely process payments and receive earnings from your subscribers."}
			</Typography>

			{!loading && (
				<Button
					variant='outlined'
					color='primary'
					fullWidth
					onClick={handleConnectStripe}
					sx={{ mb: 1 }}>
					{hasStripeAccount ? 'Update Account' : 'Connect Account'}
				</Button>
			)}

			{/* Onboarding Modal */}
			<Dialog
				open={openOnboardingModal}
				onClose={() => setOpenOnboardingModal(false)}
				maxWidth={false}
				PaperProps={{
					sx: { maxWidth: '300px', py: 2 },
				}}>
				<DialogTitle>
					{hasStripeAccount ? 'Update Account' : 'Create Account'}
				</DialogTitle>
				<DialogContent>
					<Typography>
						{hasStripeAccount
							? 'You will now be redirected to Stripe to update your account settings. Are you ready to proceed?'
							: 'You will now be redirected to Stripe to complete your account setup. Are you ready to proceed?'}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenOnboardingModal(false)}>Cancel</Button>
					<Button
						onClick={handleProceedToOnboarding}
						variant='outlined'
						color='primary'>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

const mapStateToProps = (state: IStore) => ({
	userData: state.userData,
});

export default connect(mapStateToProps)(StripeConnectAccountUpdate);
