import React from 'react';
import { connect } from 'react-redux';
import { Box, styled, Typography } from '@mui/material';
import { IStore, ICourseListItem } from '../../types/interfaces';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = ({ educatorProfileData }: IStore) => ({
	educatorProfileData,
});

interface IProps {
	course: ICourseListItem;
}

function CourseCard({ course }: IProps) {
	const navigate = useNavigate();

	const StyledContainer = styled(Box)(({ theme }) => ({
		marginRight: theme.spacing(2), // Equivalent to marginRight: 15
		width: 104, // Fixed width for the card
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	}));

	const StyledImage = styled('img')({
		width: 160,
		borderRadius: 4,
	});
	const handlePress = () => {
		navigate(`/app/courses/${course.id}`);
	};
	return (
		<StyledContainer
			sx={{
				width: 160,
				marginRight: 2,
				pb: 0,
				backgroundColor: 'transparent',
				boxShadow: 'none',
				cursor: 'pointer',
			}}
			onClick={handlePress}>
			<StyledImage
				alt={course.name}
				src={course['descriptive_image']}
				sx={{ height: 150 }}
			/>
			<div style={{ paddingTop: 7, width: '100%' }}>
				<Typography
					variant='body1'
					sx={{
						fontSize: 16,
						paddingX: 0,
						lineHeight: 1.2, // Adjust line height as needed
						maxHeight: '48px', // 2 lines * line-height
						overflow: 'hidden',
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 2,

						overflowWrap: 'break-word', // Allows breaking long words
						wordWrap: 'break-word', // Legacy support
						hyphens: 'auto', // Adds hyphenation
						textOverflow: 'ellipsis', // Optional: adds ellipsis for overflowing text
						whiteSpace: 'normal', // Allows the text to wrap
					}}>
					{course.name}
				</Typography>
				<Typography
					variant='body2'
					color='text.secondary'
					noWrap
					sx={{
						overflowWrap: 'break-word', // Allows breaking long words
						wordWrap: 'break-word', // Legacy support
						hyphens: 'auto', // Adds hyphenation
						overflow: 'hidden', // Prevents overflow
						textOverflow: 'ellipsis', // Optional: adds ellipsis for overflowing text
						whiteSpace: 'normal',
					}}>
					{course['tutor_name']}
				</Typography>
			</div>
		</StyledContainer>
	);
}

export default connect(mapStateToProps)(CourseCard);
