import {
	AUDIO_URL,
	USERS_URL,
	SIGN_UP_URL,
	LOGIN_URL,
	GET_ALL_COURSES_URL,
	FORGOT_PASSWORD_URL,
	GET_COURSES_BY_CATEGORY_URL,
} from './constants';
import { store } from '../store/store';
import {
	performSignOut,
	resetCourseCategory,
	setCourseCategory,
	setUserData,
	updateCourseCategoryByName,
} from '../store/actions';
import { axiosDefault, axiosWithNoAuth } from './axios';
import { getTokens } from '../store/asyncStorage';
import { defaultTopAvailableCategories } from '../store/data';
import { showNetworkErrorMessageIfNetworkError } from './axios';
import {
	ICourse,
	ToasterType,
	IUser,
} from '../types/interfaces';
import { ToasterService } from '../MainUtils/ToasterService';
import { ToasterDuration } from '../types/constants';
import { AxiosError } from 'axios';

/* *********************** API REQUESTS ********** */

export const loginRequest = (body: any) => {
	console.log(LOGIN_URL)
	return axiosWithNoAuth.post(LOGIN_URL, body).then((res) => {
		return res?.data;
	});
};

export const signupRequest = (body: any) => {
	return axiosWithNoAuth.post(SIGN_UP_URL, body).then((res) => res?.data);
};
export const setUserDataAndCoursesInStore = async () => {
	store.dispatch(resetCourseCategory());
	const tokens = await getTokens();
	const res = await axiosDefault(USERS_URL + tokens?.id);
	const data = res?.data || null;
	store.dispatch(setUserData(data));
	const interests: string[] = data ? [...data?.interests] : [];

	await getAndSetCourseCategories(interests);
};
export async function getAndSetCourseCategories(interests: string[]) {
	if (interests.length < 5) {
		for (let i = 0; interests.length <= 5; i++) {
			if (!interests.includes(defaultTopAvailableCategories[i])) {
				interests.push(defaultTopAvailableCategories[i]);
			}
		}
	}

	const coursesRes = await axiosDefault.post(GET_COURSES_BY_CATEGORY_URL, {
		categories: interests,
	});

	const categoriesWithCourses = coursesRes?.data.categories || [];
	categoriesWithCourses.forEach((categoryWithCourses: any) => {
		store.dispatch(setCourseCategory(categoryWithCourses));
	});
}

export async function loadAndUpdateCourseCategoryList(page: number, category: string) {
	const res = await axiosDefault(
		`${GET_ALL_COURSES_URL}?make_public=true&size=${page}&search=${category}&search_only=categories&ordering=-created_at`
	);
	const data = res?.data || null;
	const coursesList = data?.results || [];
	store.dispatch(updateCourseCategoryByName(category, coursesList));
}

export const updateUserData = async (user: Partial<IUser>) => {
	const tokens = await getTokens();
	try {
		const res = await axiosDefault.patch(USERS_URL + tokens?.id, user);
		store.dispatch(setUserData(res?.data));
	} catch (error: any) {
		apiErrorHandler(error);
	}
};

export function getFileExtensionFromURL(url: string) {
	const parts = url.split('/');
	const fileName = parts[parts.length - 1];
	const lastDotIndex = fileName.lastIndexOf('.');
	if (lastDotIndex !== -1) {
		return fileName.substring(lastDotIndex + 1);
	}
	return null; // If no file extension is found
}

export const fetchCourseById = async (courseId: number): Promise<ICourse> => {
	const res = await axiosDefault(GET_ALL_COURSES_URL + courseId);
	return res?.data;
};

export const fetchLessonById = async (lessonId: number) => {
	const res = await axiosDefault(AUDIO_URL + lessonId);
	return res;
};

//general api error handlerFunction
export const apiErrorHandler = (error:AxiosError | ErrorEvent) => {
	showNetworkErrorMessageIfNetworkError(error);
	ToasterService.dispatchAddToaster({
		message: 'Sorry an error occurred!',
		duration: ToasterDuration.SHORT,
		type: ToasterType.ERROR,
	});
};

export const requestNewPassword = (body: any) => {
	return axiosWithNoAuth
		.put(FORGOT_PASSWORD_URL, body)
		.then((res) => res?.data);
};

export const signOutFunction = () => {
	store.dispatch(performSignOut());
}