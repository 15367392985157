import React from 'react';
import { Box, Typography, Chip, Link } from '@mui/material';
import { IPaymentMethod } from '../../types/interfaces';

interface PaymentMethodDisplayProps {
	paymentMethod: IPaymentMethod;
	onChangePaymentMethod: () => void;
}

export function PaymentMethodDisplay({
	paymentMethod,
	onChangePaymentMethod,
}: PaymentMethodDisplayProps) {
	return (
		<Box
			sx={{
				border: 1,
				borderRadius: 2,
				p: 2,
				bgcolor: 'grey.50',
				borderColor: 'grey.300',
			}}>
			<Typography variant='subtitle1' sx={{ mb: 1, fontWeight: 500 }}>
				Current Payment Method
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
				<Box sx={{ flex: 1 }}>
					<Typography color='text.primary'>
						{paymentMethod.card?.brand.toUpperCase()} ••••{' '}
						{paymentMethod.card.last4}
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Expires {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
					</Typography>
				</Box>

				<Chip
					label='Default'
					size='small'
					sx={{
						bgcolor: 'primary.50',
						color: 'primary.main',
						fontSize: '0.75rem',
					}}
				/>
			</Box>
			<Link
				component='button'
				onClick={(e) => {
					e.preventDefault();
					onChangePaymentMethod();
				}}
				sx={{
					mt: 2,
					color: 'primary.main',
					'&:hover': {
						color: 'primary.dark',
					},
					cursor: 'pointer',
					textDecoration: 'none',
					fontWeight: 600,
				}}>
				Change Payment Method
			</Link>
			<Typography variant='body2' color='text.secondary' sx={{ mt: 1 }}>
				Note: Changing your payment method will update the card used for this
				and all future subscription payments.
			</Typography>
		</Box>
	);
}
