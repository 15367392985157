import React, { useState, useEffect } from 'react';
import {
	TextField,
	Button,
	Typography,
	Box,
	CircularProgress,
	Alert,
	Link,
	Modal,
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // or any routing method you use
import { loginRequest } from '../../api/utils'; // Assuming you have this function
import { getTokensExpirationStatus, storeTokens, storeTokensExpTime } from '../../store/asyncStorage';
import { ProfileService } from '../../api/profileService';
import { ToasterService } from '../../MainUtils/ToasterService';
import { ToasterType } from '../../types/interfaces';
// Utility function to detect the OS
const getMobileOS = (): 'Android' | 'iOS' | 'unknown' => {
	const userAgent = navigator.userAgent || navigator.vendor;

	// Check for Android
	if (/android/i.test(userAgent)) {
		return 'Android';
	}

	// Check for iOS devices
	if (/iPhone|iPad|iPod/.test(userAgent)) {
		return 'iOS';
	}

	// If neither, return 'unknown'
	return 'unknown';
};
const os = getMobileOS();
const appLink =
	os === 'Android'
		? 'https://play.google.com/store/apps/details?id=com.example.androidapp' // Replace with actual Android app link
		: 'https://apps.apple.com/app/id1234567890'; // Replace with actual iOS app link
// Signup Prompt Modal
const SignupPromptModal = ({
	open,
	handleClose,
}: {
	open: boolean;
	handleClose: () => void;
}) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					p: 2,
					bgcolor: 'background.paper',
					m: 'auto',
					mt: '60%',
					width: 400,
					textAlign: 'center',
				}}>
				<Typography variant='h6' component='h2'>
					Open App
				</Typography>
				<Typography sx={{ mt: 2 }}>
					You can sign up using the mobile app. Would you like to open it now?
				</Typography>
				<Button
					sx={{ mt: 2 }}
					variant='outlined'
					color='primary'
					href={appLink}
					onClick={handleClose}>
					Open App
				</Button>
				<Button
					variant='outlined'
					sx={{ mt: 2, ml: 2, backgroundColor: '#F4F5F9' }}
					onClick={handleClose}>
					Cancel
				</Button>
			</Box>
		</Modal>
	);
};
const ForgotPasswordModal = ({
	open,
	handleClose,
}: {
	open: boolean;
	handleClose: () => void;
}) => (
	<Modal open={open} onClose={handleClose}>
		<Box
			sx={{
				p: 2,
				bgcolor: 'background.paper',
				m: 'auto',
				mt: '60%',
				width: 400,
				textAlign: 'center',
			}}>
			<Typography variant='h6' component='h2'>
				Password Reset Information
			</Typography>
			<Typography sx={{ mt: 2 }}>
				You can reset your password using the Memoryze mobile app.
			</Typography>
			<Button
				sx={{ mt: 2 }}
				variant='outlined'
				color='primary'
				href={appLink}
				onClick={handleClose}>
				Open App
			</Button>
			<Button
				variant='outlined'
				sx={{ mt: 2, ml: 2, backgroundColor: '#F4F5F9' }}
				onClick={handleClose}>
				Cancel
			</Button>
		</Box>
	</Modal>
);

function Login() {
	const navigate = useNavigate();
	const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

	const [userInfo, setUserInfo] = useState({ email: '', password: '' });
	const [error, setError] = useState<string | null>(null);
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [isSignupModalOpen, setSignupModalOpen] = useState(false);

	useEffect(() => {
		if (getTokensExpirationStatus()?.isUserLoggedIn) {
			navigate('/app/search');
		}
	}, [navigate]);

	const handleOpenSignupModal = () => {
		setSignupModalOpen(true);
	};

	const handleCloseSignupModal = () => {
		setSignupModalOpen(false);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserInfo({ ...userInfo, email: event.target.value.trim() });
		setError(null);
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserInfo({ ...userInfo, password: event.target.value.trim() });
		setError(null);
	};

	const handleSubmit = () => {
		setIsLoggingIn(true);
		const body = {
			email: userInfo.email.toLowerCase(),
			password: userInfo.password,
		};

		loginRequest(body)
			.then((res) => {
				storeTokensExpTime();
				if (res?.is_verified) {
					storeTokens(res);
					ProfileService.loadAndStoreProfileData(res.id);
					navigate('/app/search');
					setIsLoggingIn(false);
				} else {
					ToasterService.dispatchAddToaster({
						message: 'You need to login on the mobile app to verify your email',
						duration: 3000,
						type: ToasterType.INFO,
					});
				}
			})
			.catch(() => {
				setError('Oops! Wrong email or password.');
				setIsLoggingIn(false);
			});
	};
	const handleForgotPasswordClick = () => {
		setForgotPasswordOpen(true);
	};

	const handleForgotPasswordClose = () => {
		setForgotPasswordOpen(false);
	};
	const isFormInvalid = !userInfo.email || !userInfo.password;

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					mt: 8,
				}}>
				<Typography component='h1' variant='h5' sx={{ mb: 4 }}>
					Log In
				</Typography>

				{/* Form starts here */}
				<form style={{ width: '100%' }}>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email Address'
						name='email'
						autoComplete='email'
						autoFocus
						value={userInfo.email}
						onChange={handleEmailChange}
						error={!!error}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						autoComplete='current-password'
						value={userInfo.password}
						onChange={handlePasswordChange}
						error={!!error}
					/>
					{error && <Alert severity='error'>{error}</Alert>}

					<Box
						sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
						<Typography variant='body2'>
							<Link
								component='button'
								variant='body2'
								onClick={handleForgotPasswordClick}
								sx={{ color: '#2F69F8', textDecoration: 'none' }}>
								Forgot Password?
							</Link>
						</Typography>
					</Box>

					{/* Button inside the form to submit */}
					<Button
						fullWidth
						variant='outlined'
						color='primary'
						onClick={handleSubmit}
						sx={{ mt: 3, mb: 2 }}
						disabled={isFormInvalid || isLoggingIn}>
						Login{' '}
						{isLoggingIn && (
							<CircularProgress size={24} color='inherit' sx={{ ml: 2 }} />
						)}
					</Button>
				</form>
				{/* Form ends here */}

				<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
					<Typography variant='body2'>
						Don't have an account?{' '}
						<Link
							component='button'
							variant='body2'
							sx={{ color: '#2F69F8', textDecoration: 'none' }}
							onClick={handleOpenSignupModal}>
							Sign Up
						</Link>
					</Typography>
				</Box>

				<SignupPromptModal
					open={isSignupModalOpen}
					handleClose={handleCloseSignupModal}
				/>

				<ForgotPasswordModal
					open={forgotPasswordOpen}
					handleClose={handleForgotPasswordClose}
				/>
			</Box>
		</>
	);
}

export default Login;
