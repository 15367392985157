import {
	IEducatorMembershipData,
	IEducatorProfileData,
	IUser,
} from '../types/interfaces';
import * as ACTION_TYPES from './action.types';

// action creators

export const setUserId = (userId) => {
	return {
		type: ACTION_TYPES.SET_USER_DATA,
		userId,
	};
};
export const performSignOut = () => {
	return {
		type: ACTION_TYPES.SIGN_OUT,
	};
};
export const performStoreReset = () => {
	return {
		type: ACTION_TYPES.RESET_STORE,
	};
};




export const setCourseCategory = (category) => {
	return {
		type: ACTION_TYPES.SET_COURSE_CATEGORY,
		category,
	};
};

export const resetCourseCategory = () => {
	return {
		type: ACTION_TYPES.RESET_COURSE_CATEGORY,
	};
};

export const updateCourseCategoryByName = (category, coursesList) => {
	return {
		type: ACTION_TYPES.UPDATE_COURSE_CATEGORY_BY_NAME,
		category,
		coursesList,
	};
};

export const setUserData = (userData: IUser | undefined) => {
	return {
		type: ACTION_TYPES.SET_USER_DATA,
		userData,
	};
};

export const setEducatorProfileData = (
	educatorProfileData: Partial<IEducatorProfileData>
) => {
	return {
		type: ACTION_TYPES.SET_EDUCATOR_PROFILE_DATA,
		educatorProfileData,
	};
};

export const setSearchHistory = (searchHistory) => {
	return {
		type: ACTION_TYPES.SET_SEARCH_HISTORY,
		searchHistory,
	};
};

export const setEducatorMembershipData = (
	educatorMembershipData: IEducatorMembershipData | undefined
) => {
	return {
		type: ACTION_TYPES.SET_EDUCATOR_MEMBERSHIP_DATA,
		educatorMembershipData,
	};
};
