import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function CheckoutCanceled() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '80vh',
				padding: 3,
				textAlign: 'center',
			}}>
			<ErrorOutlineIcon sx={{ fontSize: 64, color: 'error.main', mb: 2 }} />

			<Typography variant='h4' component='h1' gutterBottom>
				Subscription Canceled
			</Typography>

			<Typography
				variant='body1'
				color='text.secondary'
				sx={{ mb: 4, maxWidth: 600 }}>
				You've canceled your subscription process. No charges have been made.
				You can try subscribing again whenever you're ready.
			</Typography>

			<Box>
				<Button
					variant='contained'
					onClick={() => navigate(localStorage.getItem('previousPage') || '/app')}
					sx={{ minWidth: 120 }}>
					Go Back
				</Button>
			</Box>
		</Box>
	);
}
