import React from 'react';
// const key = process.env.REACT_APP_GET_WAIT_LIST_API_KEY;
function GetWaitList() {
	return (
		<main className='main container'>
			
		</main>
	);
}

export default GetWaitList;
