import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

import { IToasterOptions } from '../../types/interfaces';
import { ToasterService } from '../../MainUtils/ToasterService';

const ToastProvider = ({ children }: any) => {
	const [toaster, setToaster] = useState<IToasterOptions | null>(null);
	const [open, setOpen] = useState(false);

	ToasterService.setOpenSnackbar((val) => {
		setToaster(val);
		setOpen(true);
	});

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			{children}
			<Snackbar
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				sx={{ marginTop: 7 }}
				autoHideDuration={toaster?.duration || 3000}>
				<Alert
					onClose={handleClose}
					severity={toaster?.type}
					variant='filled'
					sx={{ width: '100%' }}>
					{toaster?.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default ToastProvider;
