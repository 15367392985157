import { setSearchHistory } from "../store/actions";
import { store } from "../store/store";
import { axiosDefault } from "./axios";
import {
	GET_COMBINED_SEARCH_HISTORY_URL,
	SEARCHED_EDUCATORS_URL,
	SEARCHED_AUDIOS_URL,
	SEARCHED_COURSES_URL,
} from './constants';
export const loadAndStoreSearchHistory = async () => {
	const response = await axiosDefault(GET_COMBINED_SEARCH_HISTORY_URL);
	const searchHistory = response?.data ?? []
	store.dispatch(setSearchHistory([...searchHistory]));
};

export const updateSearchedEducator = async (educatorId) => {
	const data = { educator_id: educatorId };
	await axiosDefault.post(SEARCHED_EDUCATORS_URL, data);
	loadAndStoreSearchHistory();
};
export const updateSearchedLessons = async (lessonId) => {
	const data = { audio_id: lessonId };
	await axiosDefault.post(SEARCHED_AUDIOS_URL, data);
	loadAndStoreSearchHistory();
};
export const updateSearchedCourses = async (courseId) => {
	const data = { course_id: courseId };
	await axiosDefault.post(SEARCHED_COURSES_URL, data);
	loadAndStoreSearchHistory();
};
export const deleteSearchedEducator = async (educatorId) => {
	await axiosDefault.delete(SEARCHED_EDUCATORS_URL + educatorId);
	loadAndStoreSearchHistory();
};
export const deleteSearchedLesson = async (searchedLessonId) => {
	await axiosDefault.delete(SEARCHED_AUDIOS_URL + searchedLessonId);
	loadAndStoreSearchHistory();
};

export const deleteSearchedCourse = async (searchedCourseId) => {
	await axiosDefault.delete(SEARCHED_COURSES_URL + searchedCourseId);
	loadAndStoreSearchHistory();
};
