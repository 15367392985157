import {
	IEducatorProfileData,
	IStore,
} from '../types/interfaces';

/*
Reducers must always follow some specific rules:

They should only calculate the new state value based on the state and action arguments
They are not allowed to modify the existing state. Instead, they must make immutable updates, by copying the existing state and making changes to the copied values.
They must not do any asynchronous logic, calculate random values, or cause other "side effects"
 */

export function applySetUserId(state, action) {
	return {
		...state,
		userId: action.userId,
	};
}

export function applyStoreRest(defState) {
	return { ...defState };
}


export function applySetCourseCategory(state: IStore, { category }) {
	return {
		...state,
		courseCategories: [...state.courseCategories, category],
	};
}
export function applyResetCourseCategory(state) {
	return {
		...state,
		courses: [],
	};
}
/** Update Course Category By Category Name */
export function applyUpdateCourseCategoryByName(
	state,
	{ category, coursesList }
) {
	const newCourseCategories = state.courseCategories?.map((cC) => {
		if (cC.category === category) {
			const newCC = { ...cC };
			newCC.courses = [...coursesList];
			return newCC;
		}
		return cC;
	});
	return {
		...state,
		courseCategories: [...newCourseCategories],
	};
}

export function applySetUserData(state, { userData }) {
	return {
		...state,
		userData,
	};
}


export function applySetEducatorProfileData(
	state: IStore,
	{
		educatorProfileData,
	}: { educatorProfileData: Partial<IEducatorProfileData> }
) {
	const newEducatorProfileData: IEducatorProfileData = {
		...state.educatorProfileData,
		...educatorProfileData,
	} as IEducatorProfileData;
	return {
		...state,
		educatorProfileData: newEducatorProfileData,
	};
}

export function applySetSearchHistory(state, { searchHistory }) {
	return {
		...state,
		searchHistory,
	};
}

export function applySetEducatorMembershipData(
	state,
	{ educatorMembershipData }
) {
	return {
		...state,
		educatorMembershipData,
	};
}
