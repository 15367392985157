export const perksData = [
	{
		perk: 'Get paid',
		description:
			"Earn royalties from your courses the same way artists earn from their music. The more learners you get, the more money you earn. It's that simple.",
		image: '/images/vectors/forEducators/GetPaid.png', // Updated path
	},
	{
		perk: 'Get heard',
		description:
			"You have so much to say and it is high time you had a platform that gives you a voice. You're way too smart for the world not to hear you speak.",
		image: '/images/vectors/forEducators/GetHeard.png', // Updated path
	},
	{
		perk: 'Teach learners',
		description:
			'There are very few things as wholesome and rewarding as knowing someone considers you their teacher. Helping other people achieve their learning goals is just one of the pros of being an educator.',
		image: '/images/vectors/forEducators/TeachLearners.png', // Updated path
	},
	{
		perk: 'Become Global',
		description:
			'You get to build your personal brand and establish yourself as an expert. You might not become Beyoncé or Dwayne Johnson, but you can definitely become a global figure in your own right.',
		image: '/images/vectors/forEducators/Planet.png', // Updated path
	},
	{
		perk: 'Join the Community',
		description:
			'Become part of a community of amazing educators and learners. Meet new people, share experiences, and learn from them.',
		image: '/images/vectors/forEducators/SocialMedia.png', // Updated path
	},
	{
		perk: 'Own your rights',
		description:
			"You own, in its entirety, the rights to whatever course you create. You control how it's used. No questions asked because you are the Boss.",
		image: '/images/vectors/forEducators/OwnYourRights.png', // Updated path
	},
];

export const processesData = [
	{
		process: 'Sign up',
		description:
			"Click the 'Get started' button and get signed up as an educator.",
		image: '/images/vectors/forEducators/SignUp.png', // Updated path
	},
	{
		process: 'Record',
		description:
			"Next, you start creating courses; it takes 2 - 4 weeks to create a course. Don't fret if this is your first time creating an audio course. We would be with you throughout the process.",
		image: '/images/vectors/forEducators/SocialMedia.png', // Updated path
	},
	{
		process: 'Upload',
		description:
			"When you're done creating your course, you upload it. It's time to promote and market it to your audience.",
		image: '/images/vectors/forEducators/Upload.png', // Updated path
	},
	{
		process: 'Earn',
		description:
			"You've done it! Your course is getting a lot of plays. It's time for you to reap the fruits of your hard work.",
		image: '/images/vectors/forEducators/Investing.png', // Updated path
	},
];
