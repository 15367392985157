import * as ACTION_TYPES from './action.types';
import { performStoreReset } from './actions';
import history from '../MainUtils/history';

export const clearStorage = async () => {
	try {
		localStorage.clear(); // Clear all items in local storage
	} catch (error) {
		console.error('Error clearing local storage:', error);
	}
};

export const effectsMiddleware = (storeAPI) => (next) => async (action) => {
	// Your middleware logic goes here
	switch (action?.type) {
		case ACTION_TYPES.SIGN_OUT:
			history.push('/app/login');
			storeAPI.dispatch(performStoreReset()); // dispatch action to reset the store
			clearStorage();

			break;
		default:
			// For actions that don't require special handling
			break;
	}

	// Call the next middleware in the chain
	return next(action);
};
